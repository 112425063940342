import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import {
  CompareTimeRangesBody,
  CompareTimeRangesBodyChartAndDetailsContainer,
  CompareTimeRangesContainer,
  CompareTimeRangesHeader,
} from "../../../common-styles/styled-compare-time-in-ranges/styled-compare-time-in-ranges";
import { useLoadedCompareRight } from "../../context/loaded-compare-right-context";
import { CompareRightTimeRangesChart } from "./compare-right-time-ranges-chart";
import { CompareRightTimeRangesDetails } from "./compare-right-time-ranges-details";

export const CompareRightTimeRanges = () => {
  const { patientCGMEntryDTOs } = useLoadedCompareRight();

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <CompareTimeRangesContainer>
        <CompareTimeRangesHeader>
          <p>Time Ranges</p>
        </CompareTimeRangesHeader>
        <CompareTimeRangesBody>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </CompareTimeRangesBody>
      </CompareTimeRangesContainer>
    );
  }

  return (
    <CompareTimeRangesContainer>
      <CompareTimeRangesHeader>
        <p>Time Ranges</p>
      </CompareTimeRangesHeader>
      <CompareTimeRangesBody>
        <CompareTimeRangesBodyChartAndDetailsContainer>
          <div className="arrow compare-right-arrow arrow-top" />
          <CompareRightTimeRangesChart />
          <CompareRightTimeRangesDetails />
          <div className="arrow compare-right-arrow arrow-bottom" />
        </CompareTimeRangesBodyChartAndDetailsContainer>
      </CompareTimeRangesBody>
    </CompareTimeRangesContainer>
  );
};
