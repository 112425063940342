import { PatientCGMEntryDTO } from "../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientDTO } from "../../../../../../../../models/patient-dtos/patient-dto";
import { PatientType } from "../../../../../../../../models/patient-dtos/patient-type";
import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";

type GlucoseBGRanges = {
  veryHigh: number;
  high: number;
  target: number;
  low: number;
  veryLow: number;
};

export function calculateGlucoseBGRangesPercentage(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  minRange: number,
  maxRange: number,
  patientDTO: PatientDTO
): GlucoseBGRanges {
  let veryHigh = 0;
  let high = 0;
  let target = 0;
  let low = 0;
  let veryLow = 0;

  for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
    const glucoseMGPerDL = patientCGMEntryDTO.glucoseMGPerDL;
    const tag = patientCGMEntryDTO.tag;

    if (glucoseMGPerDL > 250) veryHigh += 1;
    if (glucoseMGPerDL > maxRange && glucoseMGPerDL <= 250) high += 1;
    if (isGlucosePointInRange(patientDTO.type, glucoseMGPerDL, tag))
      target += 1;
    if (glucoseMGPerDL >= 54 && glucoseMGPerDL < minRange) low += 1;
    if (glucoseMGPerDL < 54) veryLow += 1;
  }

  return {
    veryHigh:
      veryHigh > 0
        ? roundTo1DecimalPlace((veryHigh / patientCGMEntryDTOs.length) * 100)
        : 0,
    high:
      high > 0
        ? roundTo1DecimalPlace((high / patientCGMEntryDTOs.length) * 100)
        : 0,
    target:
      target > 0
        ? roundTo1DecimalPlace((target / patientCGMEntryDTOs.length) * 100)
        : 0,
    low:
      low > 0
        ? roundTo1DecimalPlace((low / patientCGMEntryDTOs.length) * 100)
        : 0,
    veryLow:
      veryLow > 0
        ? roundTo1DecimalPlace((veryLow / patientCGMEntryDTOs.length) * 100)
        : 0,
  };
}

export function isGlucosePointInRange(
  type: PatientType,
  glucoseMGPerDL: number,
  tag?: string
) {
  if (tag === "fasting") {
    if (type === "NormalOrHealthy" || type === "Prediabetic") {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 100;
    } else if (type === "Type1Pediatric") {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 144;
    } else {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 130;
    }
  } else {
    if (type === "NormalOrHealthy" || type === "Prediabetic") {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 140;
    } else {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 180;
    }
  }
}
