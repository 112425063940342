import { PatientCGMEntryDTO } from "../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { convertCGMEntryToReading } from "../../../../../../../utils/glucose-point-utils/convert-cgm-entry-to-reading";
import { isGlucosePointVeryLow } from "../../../../../../../utils/glucose-point-utils/glucose-point-range-utils/is-glucose-point-very-low";

export function buildVeryLowReadings(
  patientCGMEntryDTOs: PatientCGMEntryDTO[]
) {
  const veryLowReadings: PatientCGMEntryDTO[] = [];

  for (const entry of patientCGMEntryDTOs) {
    const reading = convertCGMEntryToReading(entry);

    if (isGlucosePointVeryLow(reading)) {
      veryLowReadings.push(entry);
    }
  }

  return { veryLowReadings };
}
