import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { PatientCGMEntryDTO } from "../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import {
  agpCalculatePercentile,
  AgpPercentile,
} from "../../../agp-report/agp-report-response/utils/agp-calculate-percentile";
import { timeIntervals } from "../../../agp-report/agp-report-response/utils/agp-time-intervals";
import { useLoadedCompareRight } from "./loaded-compare-right-context";

export type CompareRightAgp = {
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
};

export type GroupCGMEntriesByTimeInterval = {
  time: string;
  cgmEntries: PatientCGMEntryDTO[];
};

type TCompareRightAgp = {
  sortedCgmEntriesByTime: PatientCGMEntryDTO[];
  groupCGMEntriesByTimeInterval: GroupCGMEntriesByTimeInterval[];
  sortedGroupCGMEntriesByGlucose: GroupCGMEntriesByTimeInterval[];
  percentile5: AgpPercentile[];
  percentile25: AgpPercentile[];
  percentile50: AgpPercentile[];
  percentile75: AgpPercentile[];
  percentile95: AgpPercentile[];
};

const CompareRightAgpContext = createContext<TCompareRightAgp | undefined>(
  undefined
);

type CompareRightAgpProviderProps = PropsWithChildren<{}>;

export function CompareRightAgpProvider(props: CompareRightAgpProviderProps) {
  const { children } = props;

  const { patientCGMEntryDTOs } = useLoadedCompareRight();

  const historicPatientCGMEntryDTOs = useMemo(
    () =>
      patientCGMEntryDTOs.filter(
        (cgmEntry) => cgmEntry.recordType === "HistoricGlucose"
      ),
    [patientCGMEntryDTOs]
  );

  const sortedCgmEntriesByTime = useMemo(
    () =>
      historicPatientCGMEntryDTOs.sort((a, b) => {
        const timeA = a.time.split(":").map(Number);
        const timeB = b.time.split(":").map(Number);

        if (timeA[0] !== timeB[0]) {
          return timeA[0] - timeB[0];
        }

        if (timeA[1] !== timeB[1]) {
          return timeA[1] - timeB[1];
        }

        return timeA[2] - timeB[2];
      }),
    [historicPatientCGMEntryDTOs]
  );

  const groupCGMEntriesByTimeInterval = useMemo((): GroupCGMEntriesByTimeInterval[] => {
    const groupCGMEntriesByTimeInterval: GroupCGMEntriesByTimeInterval[] = [];

    // Grouping logic
    sortedCgmEntriesByTime.forEach((cgmEntry) => {
      timeIntervals().forEach((interval) => {
        if (
          (cgmEntry.time >= interval.from && cgmEntry.time <= interval.to) ||
          (interval.from > interval.to &&
            (cgmEntry.time >= interval.from || cgmEntry.time <= interval.to))
        ) {
          const intervalLabel = `from: ${interval.from} till: ${interval.to}`;
          const existingEntry = groupCGMEntriesByTimeInterval.find(
            (g) => g.time === intervalLabel
          );

          if (existingEntry) {
            existingEntry.cgmEntries.push(cgmEntry);
          } else {
            groupCGMEntriesByTimeInterval.push({
              time: intervalLabel,
              cgmEntries: [cgmEntry],
            });
          }
        }
      });
    });

    // Managing the last item
    // And this if condition is for safty,
    // because if the last item is not in the time intervals,
    // it will throw an error of undefined index
    if (groupCGMEntriesByTimeInterval.length !== 0) {
      const indexOfLastTimeInterval = groupCGMEntriesByTimeInterval.findIndex(
        (g) => g.time === "from: 22:30:00 till: 01:00:00"
      );

      const [lastTimeIntervalElement] = groupCGMEntriesByTimeInterval.splice(
        indexOfLastTimeInterval,
        1
      );

      groupCGMEntriesByTimeInterval.push(lastTimeIntervalElement);
    }

    return groupCGMEntriesByTimeInterval;
  }, [sortedCgmEntriesByTime]);

  const sortedGroupCGMEntriesByGlucose = useMemo(() => {
    const sorted: GroupCGMEntriesByTimeInterval[] = [];

    for (const groupedItem of groupCGMEntriesByTimeInterval) {
      const cgmEntries = groupedItem.cgmEntries;
      const sortedCgmEntries = cgmEntries.sort(
        (a, b) => a.glucoseMGPerDL - b.glucoseMGPerDL
      );

      sorted.push({ ...groupedItem, cgmEntries: sortedCgmEntries });
    }

    return sorted;
  }, [groupCGMEntriesByTimeInterval]);

  const percentile5 = useMemo(
    () => agpCalculatePercentile(5, sortedGroupCGMEntriesByGlucose),
    [sortedGroupCGMEntriesByGlucose]
  );

  const percentile25 = useMemo(
    () => agpCalculatePercentile(25, sortedGroupCGMEntriesByGlucose),
    [sortedGroupCGMEntriesByGlucose]
  );

  const percentile50 = useMemo(
    () => agpCalculatePercentile(50, sortedGroupCGMEntriesByGlucose),
    [sortedGroupCGMEntriesByGlucose]
  );

  const percentile75 = useMemo(
    () => agpCalculatePercentile(75, sortedGroupCGMEntriesByGlucose),
    [sortedGroupCGMEntriesByGlucose]
  );

  const percentile95 = useMemo(
    () => agpCalculatePercentile(95, sortedGroupCGMEntriesByGlucose),
    [sortedGroupCGMEntriesByGlucose]
  );

  const value = useMemo(
    () => ({
      sortedCgmEntriesByTime,
      groupCGMEntriesByTimeInterval,
      sortedGroupCGMEntriesByGlucose,
      percentile5,
      percentile25,
      percentile50,
      percentile75,
      percentile95,
    }),
    [
      sortedCgmEntriesByTime,
      groupCGMEntriesByTimeInterval,
      sortedGroupCGMEntriesByGlucose,
      percentile5,
      percentile25,
      percentile50,
      percentile75,
      percentile95,
    ]
  );

  return (
    <CompareRightAgpContext.Provider value={value}>
      {children}
    </CompareRightAgpContext.Provider>
  );
}

export function useCompareRightAgp(): TCompareRightAgp {
  const CompareRightAgp = useContext(CompareRightAgpContext);

  if (CompareRightAgp === undefined) {
    throw new Error(
      "useCompareRightAgp must be used within a CompareRightAgpProvider"
    );
  }

  return CompareRightAgp;
}
