import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import { useBGMResponse } from "../../../context/bgm-response-context";
import { BGMCard, BGMCardHeader } from "../styles";
import { BGMTrendsByDayChart } from "./bgm-trends-by-day-chart/bgm-trends-by-day-chart";

export const BGMTrendsByDay = () => {
  const { patientCGMEntryDTOs } = useBGMResponse();

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <BGMCard>
        <BGMCardHeader>
          <p>Weekly Glucose Trends by Day</p>
        </BGMCardHeader>
        <div>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </div>
      </BGMCard>
    );
  }

  return (
    <BGMCard>
      <BGMCardHeader>
        <p>Weekly Glucose Trends by Day</p>
      </BGMCardHeader>
      <BGMTrendsByDayChart />
    </BGMCard>
  );
};
