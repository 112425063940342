import { PatientCGMEntryDTOWithBgmTags } from "../../../../utils/types";

export type BGMGlucoseTagSeries = {
  x: Date;
  y: number;
};

export function buildBGMGlucoseTagSeries(
  patientCGMEntryDTOWithBgmTags: PatientCGMEntryDTOWithBgmTags[]
) {
  const bgmGlucoseTagSeries: BGMGlucoseTagSeries[] = [];

  for (const reading of patientCGMEntryDTOWithBgmTags) {
    const dateString = `${reading.date}T00:00:00`;

    bgmGlucoseTagSeries.push({
      x: new Date(dateString),
      y: reading.glucoseMGPerDL,
    });
  }

  return bgmGlucoseTagSeries;
}
