import { useParams } from "react-router-dom";
import { useDateRange } from "../../../../../components/date-range/context/date-range-context";
import { useGetPatient } from "../../../../../hooks/patient-hooks/use-get-patient";
import { useGetPatientCGMEntries } from "../../../../../hooks/use-get-patient-cgm-entries";
import { useGetPatientMeals } from "../../../../../hooks/patient-meal-hooks/use-get-patient-meals";
import { useCallback } from "react";
import { BGMResponseProvider } from "../context/bgm-response-context";
import { Source } from "../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-source";
import { BGMResponseBody } from "./bgm-response-body/bgm-response-body";
import { Loading } from "../../../../../components/loading";

export const BGMResponse = () => {
  const { id } = useParams();
  const patientID = parseInt(id!);

  const getPatient = useGetPatient();
  const getPatientCGMEntries = useGetPatientCGMEntries();
  const getPatientMeals = useGetPatientMeals();

  const { temporalStartDate, temporalEndDate } = useDateRange();

  const cgmReportResponseLoadCallback = useCallback(async () => {
    const cgmSource: Source = "Manual";

    const [patientDTO, patientCGMEntryDTOs, patientMeals] = await Promise.all([
      getPatient(patientID),
      getPatientCGMEntries(
        patientID,
        cgmSource,
        temporalStartDate,
        temporalEndDate
      ),
      getPatientMeals(patientID, true, temporalStartDate, temporalEndDate),
    ]);

    return {
      patientDTO,
      patientCGMEntryDTOs,
      patientMeals,
    };
  }, [
    patientID,
    getPatient,
    getPatientCGMEntries,
    getPatientMeals,
    temporalStartDate,
    temporalEndDate,
  ]);

  return (
    <Loading
      load={cgmReportResponseLoadCallback}
      successComponent={(data) => (
        <BGMResponseProvider bgmResponse={data}>
          <BGMResponseBody />
        </BGMResponseProvider>
      )}
    />
  );
};
