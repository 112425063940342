import { BGMTrendsByMeal } from "./bgm-trends-by-meal/bgm-trends-by-meal";
import { BGMGlucoseBG } from "./bgm-glucose-bg/bgm-glucose-bg";
import { BGMTimeOfDay } from "./bgm-time-of-day/bgm-time-of-day";
import { BGMTrendsByDay } from "./bgm-trends-by-day/bgm-trends-by-day";
import { BGMGlucoseTag } from "./bgm-glucose-tag/bgm-glucose-tag";
import { BGMResponseBodyContainer } from "./styles";

export const BGMResponseBody = () => {
  return (
    <BGMResponseBodyContainer>
      <BGMGlucoseBG />
      <BGMTimeOfDay />
      <BGMTrendsByDay />
      <BGMTrendsByMeal />
      <BGMGlucoseTag />
    </BGMResponseBodyContainer>
  );
};
