import { useMemo } from "react";
import { useBGMResponse } from "../../../../context/bgm-response-context";
import {
  BGMGlucoseBGChartContainer,
  BGMGlucoseBGChartItem,
} from "./styled-bgm-glucose-bg-chart";
import { getInRangeNumbers } from "../../../../../../../../utils/patient-type-utils";
import { calculateGlucoseBGRangesPercentage } from "../utils/glucose-bg-ranges-percentage-utils";

export const BGMGlucoseBGChart = () => {
  const { patientCGMEntryDTOs, patientDTO } = useBGMResponse();

  const inRangeNumber = getInRangeNumbers(patientDTO.type);
  const maxRange = inRangeNumber[1];
  const minRange = inRangeNumber[0];

  const { veryHigh, high, target, low, veryLow } = useMemo(
    () =>
      calculateGlucoseBGRangesPercentage(
        patientCGMEntryDTOs,
        minRange,
        maxRange,
        patientDTO
      ),
    [patientCGMEntryDTOs, minRange, maxRange, patientDTO]
  );

  return (
    <BGMGlucoseBGChartContainer>
      <BGMGlucoseBGChartItem className="very-high" $height={`${veryHigh}%`} />
      <BGMGlucoseBGChartItem className="high" $height={`${high}%`} />
      <BGMGlucoseBGChartItem className="target" $height={`${target}%`} />
      <BGMGlucoseBGChartItem className="low" $height={`${low}%`} />
      <BGMGlucoseBGChartItem className="very-low" $height={`${veryLow}%`} />
    </BGMGlucoseBGChartContainer>
  );
};
