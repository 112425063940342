import { createContext, PropsWithChildren, useContext } from "react";
import { PatientCGMEntryDTO } from "../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientDTO } from "../../../../../models/patient-dtos/patient-dto";
import { PatientMealDTO } from "../../../../../models/patient-meal-dtos/patient-meal-dto";

type BGMResponse = {
  patientDTO: PatientDTO;
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
  patientMeals: PatientMealDTO[];
};

export const BGMResponseContext = createContext<BGMResponse | undefined>(
  undefined
);

type BGMResponseProviderProps = PropsWithChildren<{
  bgmResponse: BGMResponse;
}>;

export const BGMResponseProvider = (props: BGMResponseProviderProps) => {
  const { children, bgmResponse } = props;

  return (
    <BGMResponseContext.Provider value={bgmResponse}>
      {children}
    </BGMResponseContext.Provider>
  );
};

export function useBGMResponse(): BGMResponse {
  const bgmResponse = useContext(BGMResponseContext);

  if (bgmResponse === undefined) {
    throw Error("useBGMResponse must be used within an BGMResponseProvider");
  }

  return bgmResponse;
}
