import { useMemo } from "react";
import { useBGMResponse } from "../../../../../context/bgm-response-context";
import { BGMCardFooter, BGMCardHeader } from "../../../styles";
import {
  buildAfterLunchReadings,
  buildBeforeLunchReadings,
} from "../../../../utils/bgm-response-utils";
import { roundTo1DecimalPlace } from "../../../../../../../../../utils/math-utils";
import { BGMGlucoseTagLunchChart } from "./bgm-glucose-tag-lunch-chart";
import { EmptyState } from "../../../../../../../../../components/empty-state/empty-state";
import { BGMGlucoseTagCard } from "../../styled-bgm-glucose-tag";

export const BGMGlucoseTagLunch = () => {
  const { patientMeals, patientCGMEntryDTOs } = useBGMResponse();

  const {
    beforeLunchReadings,
    afterLunchReadings,
    lunchReadings,
  } = useMemo(() => {
    const beforeLunchReadings = buildBeforeLunchReadings(
      patientCGMEntryDTOs,
      patientMeals
    );

    const afterLunchReadings = buildAfterLunchReadings(
      patientCGMEntryDTOs,
      patientMeals
    );

    const lunchReadings = [...beforeLunchReadings, ...afterLunchReadings];

    return {
      beforeLunchReadings,
      afterLunchReadings,
      lunchReadings,
    };
  }, [patientCGMEntryDTOs, patientMeals]);

  const {
    averageLunchGlucose,
    averageBeforeLunchGlucose,
    averageAfterLunchGlucose,
  } = useMemo(() => {
    const averageLunchGlucose =
      lunchReadings.reduce((sum, reading) => {
        return sum + reading.glucoseMGPerDL;
      }, 0) / lunchReadings.length;

    const averageBeforeLunchGlucose =
      beforeLunchReadings.reduce((sum, reading) => {
        return sum + reading.glucoseMGPerDL;
      }, 0) / beforeLunchReadings.length;

    const averageAfterLunchGlucose =
      afterLunchReadings.reduce((sum, reading) => {
        return sum + reading.glucoseMGPerDL;
      }, 0) / afterLunchReadings.length;

    return {
      averageLunchGlucose,
      averageBeforeLunchGlucose,
      averageAfterLunchGlucose,
    };
  }, [lunchReadings, beforeLunchReadings, afterLunchReadings]);

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <BGMGlucoseTagCard>
        <BGMCardHeader>
          <p>Before & After Lunch Glucose (mg/dl)</p>
        </BGMCardHeader>
        <div>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </div>
      </BGMGlucoseTagCard>
    );
  }

  return (
    <BGMGlucoseTagCard>
      <BGMCardHeader>
        <p>Before & After Lunch Glucose (mg/dl)</p>
        <span>
          Total Average: {roundTo1DecimalPlace(averageLunchGlucose)} mg/dl
        </span>
      </BGMCardHeader>
      <BGMGlucoseTagLunchChart
        lunchReadings={lunchReadings}
        beforeLunchReadings={beforeLunchReadings}
        afterLunchReadings={afterLunchReadings}
      />
      <BGMCardFooter>
        <div>
          <p>Avg Before Lunch Glucose</p>
          <span>{roundTo1DecimalPlace(averageBeforeLunchGlucose)} mg/dl</span>
        </div>
        <div>
          <p>Avg After Lunch Glucose</p>
          <span>{roundTo1DecimalPlace(averageAfterLunchGlucose)} mg/dl</span>
        </div>
      </BGMCardFooter>
    </BGMGlucoseTagCard>
  );
};
