import { PatientCGMEntryDTO } from "../../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientMealDTO } from "../../../../../../../../../models/patient-meal-dtos/patient-meal-dto";
import { buildGroupBGMTaggedReadingsByDate } from "../../../../utils/bgm-response-utils";
import { BGMTag, PatientCGMEntryDTOWithBgmTags } from "../../../../utils/types";

type BGMTrendsByMealChartSeries = {
  date: string;
  readings: (PatientCGMEntryDTOWithBgmTags & { color: string })[];
};

export function buildBGMTrendsByMealChartSeries(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientMeals: PatientMealDTO[]
) {
  const groupBGMTaggedReadingsByDate = buildGroupBGMTaggedReadingsByDate(
    patientCGMEntryDTOs,
    patientMeals
  );

  const bgmTrendsByMealChartSeries: BGMTrendsByMealChartSeries[] = [];

  for (const bgmTaggedReading of groupBGMTaggedReadingsByDate) {
    bgmTrendsByMealChartSeries.push({
      date: bgmTaggedReading.date,
      readings: bgmTaggedReading.readings.map((reading) => ({
        ...reading,
        color: generateSeriesColor(reading.bgmTag),
      })),
    });
  }

  const teest = groupBGMTaggedReadingsByDate[0].readings[0];

  const firstItem: BGMTrendsByMealChartSeries = {
    date: groupBGMTaggedReadingsByDate[0].date,
    readings: [
      {
        ...teest,
        glucoseMGPerDL: 0,
        bgmTag: "Fasting",
        color: generateSeriesColor("Fasting"),
      },
      {
        ...teest,
        glucoseMGPerDL: 0,
        bgmTag: "Before Breakfast",
        color: generateSeriesColor("Before Breakfast"),
      },
      {
        ...teest,
        glucoseMGPerDL: 0,
        bgmTag: "After Breakfast",
        color: generateSeriesColor("After Breakfast"),
      },
      {
        ...teest,
        glucoseMGPerDL: 0,
        bgmTag: "Before Lunch",
        color: generateSeriesColor("Before Lunch"),
      },
      {
        ...teest,
        glucoseMGPerDL: 0,
        bgmTag: "After Lunch",
        color: generateSeriesColor("After Lunch"),
      },
      {
        ...teest,
        glucoseMGPerDL: 0,
        bgmTag: "Before Dinner",
        color: generateSeriesColor("Before Dinner"),
      },
      {
        ...teest,
        glucoseMGPerDL: 0,
        bgmTag: "After Dinner",
        color: generateSeriesColor("After Dinner"),
      },
    ],
  };

  bgmTrendsByMealChartSeries.unshift(firstItem);

  return bgmTrendsByMealChartSeries;
}

function generateSeriesColor(bgmTag: BGMTag): string {
  if (bgmTag === "Fasting") {
    return "#2d9bf0";
  }
  if (bgmTag === "Before Breakfast") {
    return "#52b4d7";
  }
  if (bgmTag === "After Breakfast") {
    return "#652cb3";
  }
  if (bgmTag === "Before Lunch") {
    return "#fac710";
  }
  if (bgmTag === "After Lunch") {
    return "#da0063";
  }
  if (bgmTag === "Before Dinner") {
    return "#8fd14f";
  }
  if (bgmTag === "After Dinner") {
    return "#0860ba";
  }
  return "";
}
