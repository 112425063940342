import { roundTo1DecimalPlace } from "../../../../../../../utils/math-utils";

const displayTooltipTime = (time: Date) => {
  const date = new Date(time.toString());
  date.setHours(date.getHours() + 1);

  const startTime = new Date(time).toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  const endTime = date.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return `Between ${startTime} - ${endTime}`;
};

const tooltipContainerStyle = `'
  background: #fff; 
  width: 400px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03); border-radius: 16px;'
`;

const tooltipListItemStyle = `'
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;'
`;

const tooltipListItemContainerStyle = `'
  display: flex;
  align-items: center;
  gap: 0px 4px;
'`;

export function buildAgpSharedTooltip(args: any) {
  return `
    <div style=${tooltipContainerStyle}>
      <ul style='margin: 0px;'>
        <li style=${tooltipListItemStyle}>
         <div style=${tooltipListItemContainerStyle}>
          <div style='height: 10px;width: 10px;background: #418AC0;'></div>
          <span style='font-size: 16px;'>5% - 95%</span>
         </div>
         <div>
          <span style='font-size: 16px;'>
           ${args.point[1].low} - ${args.point[1].high} mg/dl
          </span>
          </div>
        </li>
        <li style=${tooltipListItemStyle}>
          <div style=${tooltipListItemContainerStyle}>
            <div style='height: 10px;width: 10px;background: #85A6D0;'></div>
            <span style='font-size: 16px;'>25% - 75%</span>
          </div>  
          <div>
            <span style='font-size: 16px;'>
              ${args.point[2].low} - ${args.point[2].high} mg/dl
            </span>
          </div>
        </li>
        <li style=${tooltipListItemStyle}>
          <div style=${tooltipListItemContainerStyle}>
            <div style='height: 4px;width: 10px;background: #000000;'>
            </div>
            <span style='font-size: 16px;'>Median</span>
          </div>  
          <div>
            <span style='font-size: 16px;'>
              ${roundTo1DecimalPlace(args.point[3].y)} mg/dl
            </span>
          </div>
        </li>
        <li style='padding: 8px 16px;'>
          <span style='font-size: 16px; color: #8C8E97'>
           ${displayTooltipTime(args.point[1].x)}
          </span>
        </li>
      </ul>
    </div>
  `;
}
