import { PatientCGMEntryDTO } from "../../../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientDTO } from "../../../../../../../../../../models/patient-dtos/patient-dto";
import { buildTargetReadings } from "../../../../../utils/bgm-range-readings-utils/target-readings";

type TargetReadingsSeries = {
  x: Date;
  y: number;
};

export function buildTargetReadingsSeries(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientDTO: PatientDTO
) {
  const targetReadingsSeries: TargetReadingsSeries[] = [];

  const { targetReadings } = buildTargetReadings(
    patientCGMEntryDTOs,
    patientDTO
  );

  for (const targetReading of targetReadings) {
    const date = new Date(`2024-01-01T${targetReading.time}`);

    targetReadingsSeries.push({ x: date, y: targetReading.glucoseMGPerDL });
  }

  return targetReadingsSeries;
}
