import { useMemo } from "react";
import { getInRangeNumbers } from "../../../../../../../utils/patient-type-utils";
import { calculateTimeInRangesPercentageValues } from "../../../../agp-report/agp-report-response/agp-report-response-cards/utils/time-in-ranges-percentage-utils";
import { useLoadedCompareRight } from "../../context/loaded-compare-right-context";
import {
  CompareTimeRangesChartContainer,
  CompareTimeRangesChartItem,
} from "../../../common-styles/styled-compare-time-in-ranges/styled-compare-time-in-ranges-chart";

export const CompareRightTimeRangesChart = () => {
  const { patientDTO, groupCgmEntriesByDate } = useLoadedCompareRight();

  const inRangeNumber = getInRangeNumbers(patientDTO.type);
  const maxRange = inRangeNumber[1];
  const minRange = inRangeNumber[0];

  const {
    veryHighPercentage,
    highPercentage,
    lowPercentage,
    targetPercentage,
    veryLowPercentage,
  } = useMemo(
    () =>
      calculateTimeInRangesPercentageValues(
        groupCgmEntriesByDate,
        minRange,
        maxRange,
        patientDTO
      ),
    [groupCgmEntriesByDate, minRange, maxRange, patientDTO]
  );

  return (
    <CompareTimeRangesChartContainer>
      <CompareTimeRangesChartItem
        className="very-high"
        $height={`${veryHighPercentage}%`}
      />
      <CompareTimeRangesChartItem
        className="high"
        $height={`${highPercentage}%`}
      />
      <CompareTimeRangesChartItem
        className="target"
        $height={`${targetPercentage}%`}
      />
      <CompareTimeRangesChartItem
        className="low"
        $height={`${lowPercentage}%`}
      />
      <CompareTimeRangesChartItem
        className="very-low"
        $height={`${veryLowPercentage}%`}
      />
    </CompareTimeRangesChartContainer>
  );
};
