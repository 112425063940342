import { DayAggregate } from "../../../../../../components/cgm-google-chart/utils/build-days-aggregates";
import {
  ChartCardHeader,
  ChartCardTitle,
  CircleCharts,
  DailyChartsDate,
} from "../../../daily-analysis/daily-charts/daily-chart/styled-daily-chart";
import {
  DailyFixedSummaryChartChart,
  DailyFixedSummaryChartContainerContainer,
  DailyFixedSummaryChartWrapper,
} from "./styled-daily-fixed-summary-chart-container";
import { useBglAnalysis } from "../../../context/loadable-bgl-analysis-context";
import { CgmSyncfusionChart } from "../../../../../../components/cgm-syncfusion-chart/cgm-syncfusion-chart";
import { DailyChartRanges } from "../../../daily-analysis/daily-charts/daily-chart/daily-chart-ranges/daily-chart-ranges";
import { DailyFixedSummaryChartCard } from "./daily-fixed-summary-chart-card/daily-fixed-summary-chart-card";

type DailyFixedSummaryChartContainerProps = {
  dayAggregate: DayAggregate;
  zIndex: number;
};

export const DailyFixedSummaryChartContainer = (
  props: DailyFixedSummaryChartContainerProps
) => {
  const { dayAggregate, zIndex } = props;
  const { patientDTO } = useBglAnalysis();

  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const displayDate = `${dayAggregate.date.toLocaleString(
    "en-US",
    dateOptions
  )}`;

  const cgmReadings = dayAggregate.readings.filter(
    (reading) => reading.source === "Libreview"
  );

  if (dayAggregate.date.toString() === "2024-05-21" && patientDTO.id === 130) {
    return <></>;
  }

  return (
    <DailyFixedSummaryChartContainerContainer>
      <DailyChartsDate>
        <span className="material-symbols-outlined">calendar_today</span>
        <p>{displayDate}</p>
      </DailyChartsDate>
      <DailyFixedSummaryChartWrapper>
        <DailyFixedSummaryChartChart>
          <ChartCardHeader>
            <ChartCardTitle>
              <p>Daily graph</p>
              <span>Blood glucose reading</span>
            </ChartCardTitle>
            <CircleCharts>
              {cgmReadings.length > 0 && (
                <DailyChartRanges
                  smallCircles={false}
                  dayAggregate={dayAggregate}
                  patientDTO={patientDTO}
                />
              )}
            </CircleCharts>
          </ChartCardHeader>
          <CgmSyncfusionChart
            patientDTO={patientDTO}
            dayAggregate={dayAggregate}
            zindex={zIndex}
            hasTooltip={false}
            enableLegend
            cgmChartMarkerTemplate={[
              "Patient Meals",
              "Insulin",
              "Activities",
              "BGM",
            ]}
          />
        </DailyFixedSummaryChartChart>
        <DailyFixedSummaryChartCard dayAggregate={dayAggregate} />
      </DailyFixedSummaryChartWrapper>
    </DailyFixedSummaryChartContainerContainer>
  );
};
