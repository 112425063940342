import { useCallback, useMemo } from "react";
import { useBGMResponse } from "../../../../context/bgm-response-context";
import { randomUUID } from "../../../../../../../../utils/random-uuid";
import { buildPrimaryDateTimeXAxis } from "../../../utils/bgm-charts-utils/build-primary-date-time-x-axis";
import { getInRangeNumbers } from "../../../../../../../../utils/patient-type-utils";
import { buildPrimaryYAxis } from "../../../utils/bgm-charts-utils/build-primary-y-axis";
import { buildAxisLabelRender } from "../../../utils/bgm-charts-utils/build-axis-label-render";
import {
  buildAfterBreakfastReadings,
  buildAfterDinnerReadings,
  buildAfterLunchReadings,
  buildBeforeBreakfastReadings,
  buildBeforeDinnerReadings,
  buildBeforeLunchReadings,
  buildFastingReadings,
} from "../../../utils/bgm-response-utils";
import { buildFastingSeries } from "./series/build-fasting-series";
import { buildBeforeBreakfastSeries } from "./series/build-before-breakfast-series";
import { buildAfterBreakfastSeries } from "./series/build-after-breakfast-series";
import { buildBeforeLunchSeries } from "./series/build-before-lunch-series";
import { buildAfterLunchSeries } from "./series/build-after-lunch-series";
import { buildBeforeDinnerSeries } from "./series/build-before-dinner-series";
import { buildAfterDinnerSeries } from "./series/build-after-dinner-series";
import {
  ChartComponent,
  ColumnSeries,
  DataLabel,
  DateTime,
  Inject,
  Legend,
  SeriesCollectionDirective,
  SeriesDirective,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { BGMTrendsByDayChartContainer } from "./styled-bgm-trends-by-day-chart";

export const BGMTrendsByDayChart = () => {
  const { patientDTO, patientMeals, patientCGMEntryDTOs } = useBGMResponse();

  const id = useMemo(() => `bgm-trends-by-day-chart-${randomUUID()}`, []);

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);
    // Should always enter this condition, but let's be safe
    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const primaryXAxis = useMemo(() => buildPrimaryDateTimeXAxis(), []);

  const minY = useMemo(
    () =>
      Math.min(
        ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
      ),
    [patientCGMEntryDTOs]
  );

  const { maxY } = useMemo(() => {
    let maxGlucose = Math.max(
      ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
    );
    const maxY = maxGlucose > 200 ? maxGlucose + 50 : 250;

    return { maxY: Math.floor(maxY / 10) * 10 };
  }, [patientCGMEntryDTOs]);

  const inRangeNumbers = useMemo(() => getInRangeNumbers(patientDTO.type), [
    patientDTO,
  ]);

  const primaryYAxis = useMemo(
    () => buildPrimaryYAxis(minY, maxY, inRangeNumbers),
    [minY, maxY, inRangeNumbers]
  );

  const vAxisTicks = useMemo(() => [...inRangeNumbers, maxY], [
    inRangeNumbers,
    maxY,
  ]);

  const axisLabelRender = useMemo(() => buildAxisLabelRender(vAxisTicks), [
    vAxisTicks,
  ]);

  const { fastingSeries } = useMemo(() => {
    const fastingReadings = buildFastingReadings(
      patientCGMEntryDTOs,
      patientMeals
    );

    const fastingSeries = buildFastingSeries(fastingReadings);

    return { fastingSeries };
  }, [patientCGMEntryDTOs, patientMeals]);

  const { beforeBreakfastSeries } = useMemo(() => {
    const beforeBreakfastReadings = buildBeforeBreakfastReadings(
      patientCGMEntryDTOs,
      patientMeals
    );

    const beforeBreakfastSeries = buildBeforeBreakfastSeries(
      beforeBreakfastReadings
    );

    return { beforeBreakfastSeries };
  }, [patientCGMEntryDTOs, patientMeals]);

  const { afterBreakfastSeries } = useMemo(() => {
    const afterBreakfastReadings = buildAfterBreakfastReadings(
      patientCGMEntryDTOs,
      patientMeals
    );

    const afterBreakfastSeries = buildAfterBreakfastSeries(
      afterBreakfastReadings
    );

    return { afterBreakfastSeries };
  }, [patientCGMEntryDTOs, patientMeals]);

  const { beforeLunchSeries } = useMemo(() => {
    const afterBreakfastReadings = buildBeforeLunchReadings(
      patientCGMEntryDTOs,
      patientMeals
    );

    const beforeLunchSeries = buildBeforeLunchSeries(afterBreakfastReadings);

    return { beforeLunchSeries };
  }, [patientCGMEntryDTOs, patientMeals]);

  const { afterLunchSeries } = useMemo(() => {
    const afterLunchReadings = buildAfterLunchReadings(
      patientCGMEntryDTOs,
      patientMeals
    );

    const afterLunchSeries = buildAfterLunchSeries(afterLunchReadings);

    return { afterLunchSeries };
  }, [patientCGMEntryDTOs, patientMeals]);

  const { beforeDinnerSeries } = useMemo(() => {
    const beforeDinnerReadings = buildBeforeDinnerReadings(
      patientCGMEntryDTOs,
      patientMeals
    );

    const beforeDinnerSeries = buildBeforeDinnerSeries(beforeDinnerReadings);

    return { beforeDinnerSeries };
  }, [patientCGMEntryDTOs, patientMeals]);

  const { afterDinnerSeries } = useMemo(() => {
    const afterDinnerReadings = buildAfterDinnerReadings(
      patientCGMEntryDTOs,
      patientMeals
    );

    const afterDinnerSeries = buildAfterDinnerSeries(afterDinnerReadings);

    return { afterDinnerSeries };
  }, [patientCGMEntryDTOs, patientMeals]);

  return (
    <BGMTrendsByDayChartContainer>
      <ChartComponent
        id={id}
        loaded={handleChartLoaded}
        primaryXAxis={primaryXAxis}
        primaryYAxis={primaryYAxis}
        axisLabelRender={axisLabelRender}
        height="350px"
        width="100%"
        legendSettings={{ visible: true, position: "Bottom" }}
      >
        <Inject
          services={[DataLabel, ColumnSeries, StripLine, DateTime, Legend]}
        />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={fastingSeries}
            xName="x"
            yName="y"
            type="Column"
            fill="#2d9bf0"
            name="Fasting"
            legendShape="Circle"
            columnWidthInPixel={10}
          ></SeriesDirective>
          <SeriesDirective
            dataSource={beforeBreakfastSeries}
            xName="x"
            yName="y"
            type="Column"
            fill="#52b4d7"
            name="Before Breakfast"
            legendShape="Circle"
            columnWidthInPixel={10}
          ></SeriesDirective>
          <SeriesDirective
            dataSource={afterBreakfastSeries}
            xName="x"
            yName="y"
            type="Column"
            fill="#652cb3"
            name="After Breakfast"
            legendShape="Circle"
            columnWidthInPixel={10}
          ></SeriesDirective>
          <SeriesDirective
            dataSource={beforeLunchSeries}
            xName="x"
            yName="y"
            type="Column"
            fill="#fac710"
            name="Before Lunch"
            legendShape="Circle"
            columnWidthInPixel={10}
          ></SeriesDirective>
          <SeriesDirective
            dataSource={afterLunchSeries}
            xName="x"
            yName="y"
            type="Column"
            fill="#da0063"
            name="After Lunch"
            legendShape="Circle"
            columnWidthInPixel={10}
          ></SeriesDirective>
          <SeriesDirective
            dataSource={beforeDinnerSeries}
            xName="x"
            yName="y"
            type="Column"
            fill="#8fd14f"
            name="Before Dinner"
            legendShape="Circle"
            columnWidthInPixel={10}
          ></SeriesDirective>
          <SeriesDirective
            dataSource={afterDinnerSeries}
            xName="x"
            yName="y"
            type="Column"
            fill="#0860ba"
            name="After Dinner"
            legendShape="Circle"
            columnWidthInPixel={10}
          ></SeriesDirective>
        </SeriesCollectionDirective>
      </ChartComponent>
    </BGMTrendsByDayChartContainer>
  );
};
