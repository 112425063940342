import { PatientCGMEntryDTO } from "../../../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { buildVeryLowReadings } from "../../../../../utils/bgm-range-readings-utils/very-low-readings";

type VeryLowReadingsSeries = {
  x: Date;
  y: number;
};

export function buildVeryLowReadingsSeries(
  patientCGMEntryDTOs: PatientCGMEntryDTO[]
) {
  const veryLowReadingsSeries: VeryLowReadingsSeries[] = [];

  const { veryLowReadings } = buildVeryLowReadings(patientCGMEntryDTOs);

  for (const veryLowReading of veryLowReadings) {
    const date = new Date(`2024-01-01T${veryLowReading.time}`);

    veryLowReadingsSeries.push({ x: date, y: veryLowReading.glucoseMGPerDL });
  }

  return veryLowReadingsSeries;
}
