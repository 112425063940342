import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import { useBGMResponse } from "../../../context/bgm-response-context";
import { BGMCard, BGMCardHeader } from "../styles";
import { BGMTrendsByMealChart } from "./bgm-trends-by-meal-chart/bgm-trends-by-meal-chart";
import {
  BGMTrendsByMealChartLegend,
  BGMTrendsByMealChartPoint,
  BGMTrendsByMealChartPoints,
} from "./bgm-trends-by-meal-chart/styled-bgm-trends-by-meal-chart";

const legendItems = [
  {
    title: "Fasting",
    color: "#2d9bf0",
  },
  {
    title: "Before Breakfast",
    color: "#52b4d7",
  },
  {
    title: "After Breakfast",
    color: "#6b33b6",
  },
  {
    title: "Before Lunch",
    color: "#fac710",
  },
  {
    title: "After Lunch",
    color: "#da0063",
  },
  {
    title: "Before Dinner",
    color: "#8fd14f",
  },
  {
    title: "After Dinner",
    color: "#0860ba",
  },
];

export const BGMTrendsByMeal = () => {
  const { patientCGMEntryDTOs } = useBGMResponse();

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <BGMCard>
        <BGMCardHeader>
          <p>Weekly Glucose Trends by Meal</p>
        </BGMCardHeader>
        <div>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </div>
      </BGMCard>
    );
  }

  return (
    <BGMCard>
      <BGMCardHeader>
        <p>Weekly Glucose Trends by Meal</p>
      </BGMCardHeader>
      <BGMTrendsByMealChart />
      <BGMTrendsByMealChartLegend>
        <BGMTrendsByMealChartPoints>
          {legendItems.map((item) => (
            <BGMTrendsByMealChartPoint key={item.title}>
              <div style={{ backgroundColor: item.color }} />
              <span>{item.title}</span>
            </BGMTrendsByMealChartPoint>
          ))}
        </BGMTrendsByMealChartPoints>
      </BGMTrendsByMealChartLegend>
    </BGMCard>
  );
};
