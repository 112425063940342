import { PatientCGMEntryDTOWithBgmTags } from "../../../../utils/types";

type BeforeBreakfastSeries = {
  x: Date;
  y: number;
  tooltip: PatientCGMEntryDTOWithBgmTags;
};

export function buildBeforeBreakfastSeries(
  beforeBreakfastReadings: PatientCGMEntryDTOWithBgmTags[]
) {
  const beforeBreakfastSeries: BeforeBreakfastSeries[] = [];

  for (const beforeBreakfastReading of beforeBreakfastReadings) {
    const dateString = `${beforeBreakfastReading.date}T00:00:00`;

    beforeBreakfastSeries.push({
      x: new Date(dateString),
      y: beforeBreakfastReading.glucoseMGPerDL,
      tooltip: beforeBreakfastReading,
    });
  }

  return beforeBreakfastSeries;
}
