import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { PatientDTO } from "../../../../../../models/patient-dtos/patient-dto";
import { PatientCGMEntryDTO } from "../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";

export type GroupCgmEntriesByDate = {
  date: string;
  day: string;
  readings: PatientCGMEntryDTO[];
};

export type LoadedRightCompare = {
  patientDTO: PatientDTO;
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
};

export type LoadedRightCompareReturn = {
  patientDTO: PatientDTO;
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
  groupCgmEntriesByDate: GroupCgmEntriesByDate[];
};

const LoadedCompareRightContext = createContext<
  LoadedRightCompareReturn | undefined
>(undefined);

type LoadedCompareRightProviderProps = PropsWithChildren<{
  patientDTO: PatientDTO;
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
}>;

export const LoadedCompareRightProvider = (
  props: LoadedCompareRightProviderProps
) => {
  const { patientDTO, patientCGMEntryDTOs, children } = props;

  const { groupCgmEntriesByDate } = useMemo(() => {
    const groupCgmEntriesByDate: GroupCgmEntriesByDate[] = [];

    for (const entry of patientCGMEntryDTOs) {
      const existingEntry = groupCgmEntriesByDate.find(
        (e) => e.date === entry.date
      );

      if (existingEntry) {
        existingEntry.readings.push(entry);
      } else {
        groupCgmEntriesByDate.push({
          date: entry.date,
          readings: [entry],
          day: new Date(entry.date).toLocaleDateString("en-US", {
            weekday: "long",
          }),
        });
      }
    }

    return { groupCgmEntriesByDate };
  }, [patientCGMEntryDTOs]);

  const value = useMemo(
    () => ({
      patientDTO,
      patientCGMEntryDTOs,
      groupCgmEntriesByDate,
    }),
    [patientDTO, patientCGMEntryDTOs, groupCgmEntriesByDate]
  );

  return (
    <LoadedCompareRightContext.Provider value={value}>
      {children}
    </LoadedCompareRightContext.Provider>
  );
};

export const useLoadedCompareRight = (): LoadedRightCompareReturn => {
  const context = useContext(LoadedCompareRightContext);

  if (context === undefined) {
    throw new Error(
      "useLoadedCompareRight must be used within a LoadedCompareRightProvider"
    );
  }

  return context;
};
