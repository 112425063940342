import { PatientCGMEntryDTOWithBgmTags } from "../../../../utils/types";

type AfterDinnerSeries = {
  x: Date;
  y: number;
  tooltip: PatientCGMEntryDTOWithBgmTags;
};

export function buildAfterDinnerSeries(
  afterDinnerReadings: PatientCGMEntryDTOWithBgmTags[]
) {
  const afterDinnerSeries: AfterDinnerSeries[] = [];

  for (const afterDinnerReading of afterDinnerReadings) {
    const dateString = `${afterDinnerReading.date}T00:00:00`;

    afterDinnerSeries.push({
      x: new Date(dateString),
      y: afterDinnerReading.glucoseMGPerDL,
      tooltip: afterDinnerReading,
    });
  }

  return afterDinnerSeries;
}
