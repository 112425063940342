import { PatientCGMEntryDTO } from "../../../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { buildVeryHighReadings } from "../../../../../utils/bgm-range-readings-utils/very-high-readings";

type VeryHighReadingsSeries = {
  x: Date;
  y: number;
};

export function buildVeryHighReadingsSeries(
  patientCGMEntryDTOs: PatientCGMEntryDTO[]
) {
  const veryHighReadingsSeries: VeryHighReadingsSeries[] = [];

  const { veryHighReadings } = buildVeryHighReadings(patientCGMEntryDTOs);

  for (const veryHighReading of veryHighReadings) {
    const date = new Date(`2024-01-01T${veryHighReading.time}`);

    veryHighReadingsSeries.push({ x: date, y: veryHighReading.glucoseMGPerDL });
  }

  return veryHighReadingsSeries;
}
