import { useCallback, useMemo, useRef } from "react";
import { randomUUID } from "../../../../../../../utils/random-uuid";
import {
  ChartComponent,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  SplineRangeAreaSeries,
  SplineSeries,
  StripLine,
  DateTime,
  Tooltip,
  Selection,
} from "@syncfusion/ej2-react-charts";
import { getInRangeNumbers } from "../../../../../../../utils/patient-type-utils";
import { buildPrimaryXAxis } from "../../../utils/agp-graph-utils/build-primary-x-axis";
import { buildPrimaryYAxis } from "../../../utils/agp-graph-utils/build-primary-y-axis";
import { buildAxisLabelRender } from "../../../utils/agp-graph-utils/build-axis-label-render";
import { useCompareLeftAgp } from "../../context/compare-left-agp-context";
import { useLoadedCompareLeft } from "../../context/loaded-compare-left-context";
import { buildAgpSharedTooltip } from "../../../utils/agp-graph-utils/tooltip/build-agp-shared-tooltip";
import { buildAgpSeries50th } from "../../../utils/agp-graph-utils/series/build-agp-series-50th";
import { buildAgpSeries75th25th } from "../../../utils/agp-graph-utils/series/build-agp-series-75th-25th";
import { buildAgpSeries95th5th } from "../../../utils/agp-graph-utils/series/build-agp-series-95th-5th";
import { buildAgpTooltip } from "../../../utils/agp-graph-utils/tooltip/build-agp-tooltip";
import { CompareAGPGraphContainer } from "../../../common-styles/styled-compare-agp/styled-compare-agp";

export const CompareLeftResponeAGPGraph = () => {
  const { patientDTO } = useLoadedCompareLeft();
  const {
    percentile5,
    percentile25,
    percentile50,
    percentile75,
    percentile95,
  } = useCompareLeftAgp();

  const chartRef = useRef<ChartComponent | null>(null);

  const id = useMemo(() => `agp-syncfusion-chart-${randomUUID()}`, []);

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);

    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const maxY = 350;

  const inRangeNumbers = useMemo(() => getInRangeNumbers(patientDTO.type), [
    patientDTO,
  ]);

  const vAxisTicks = useMemo(() => {
    const ticks = [0, 54, inRangeNumbers[0], inRangeNumbers[1], 250, maxY];

    return ticks;
  }, [inRangeNumbers, maxY]);

  const primaryxAxis = useMemo(
    () =>
      buildPrimaryXAxis(
        new Date(`2024-01-01T00:00:00`),
        new Date(`2024-01-02T00:00:00`)
      ),
    []
  );

  const primaryyAxis = useMemo(
    () => buildPrimaryYAxis(0, maxY, inRangeNumbers),
    [maxY, inRangeNumbers]
  );

  const axisLabelRender = useMemo(() => buildAxisLabelRender(vAxisTicks), [
    vAxisTicks,
  ]);

  const agpSeries95th5th = useMemo(
    () => buildAgpSeries95th5th(percentile5, percentile95),
    [percentile5, percentile95]
  );

  const agpSeries75th25th = useMemo(
    () => buildAgpSeries75th25th(percentile25, percentile75),
    [percentile25, percentile75]
  );

  const agpSeries50th = useMemo(() => buildAgpSeries50th(percentile50), [
    percentile50,
  ]);

  const tooltip = useMemo(() => buildAgpTooltip(), []);

  return (
    <CompareAGPGraphContainer>
      <ChartComponent
        id={id}
        primaryXAxis={primaryxAxis}
        primaryYAxis={primaryyAxis}
        axisLabelRender={axisLabelRender}
        ref={chartRef}
        loaded={handleChartLoaded}
        height="332px"
        width="100%"
        tooltip={tooltip}
        sharedTooltipRender={(args: any) => {
          args.template = buildAgpSharedTooltip(args);
        }}
        selectionMode="Series"
      >
        <Inject
          services={[
            SplineRangeAreaSeries,
            StripLine,
            SplineSeries,
            DateTime,
            Tooltip,
            Selection,
          ]}
        />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={agpSeries95th5th.map((item) => ({
              x: item.x,
              high95th: item.high95th,
            }))}
            xName="x"
            yName="high95th"
            opacity={0.6}
            type="Spline"
            width={6}
            fill="#ACB1C0"
            dashArray="5,5"
          ></SeriesDirective>
          <SeriesDirective
            dataSource={agpSeries95th5th}
            xName="x"
            yName="point95th"
            high="high95th"
            low="low5th"
            opacity={0.4}
            fill="#418AC0"
            type="SplineRangeArea"
            name="agpSeries95th5th"
          ></SeriesDirective>
          <SeriesDirective
            dataSource={agpSeries75th25th}
            xName="x"
            high="high75th"
            low="low25th"
            opacity={1}
            fill="#85A6D0"
            type="SplineRangeArea"
            border={{
              color: "transparent",
              width: 0,
            }}
            name="agpSeries75th25th"
          ></SeriesDirective>
          <SeriesDirective
            dataSource={agpSeries50th}
            xName="x"
            yName="point50th"
            opacity={1}
            type="Spline"
            width={3}
            fill="#000"
            name="agpSeries50th"
          ></SeriesDirective>
          <SeriesDirective
            dataSource={agpSeries95th5th.map((item) => ({
              x: item.x,
              low5th: item.low5th,
            }))}
            xName="x"
            yName="low5th"
            opacity={0.6}
            type="Spline"
            width={6}
            fill="#ACB1C0"
            dashArray="5,5"
          ></SeriesDirective>
        </SeriesCollectionDirective>
      </ChartComponent>
    </CompareAGPGraphContainer>
  );
};
