import { NutritionAnalysisHeaderContainer } from "./styled-nutrition-analysis-header";
import { DateRange } from "../../../../../components/date-range/date-range";

export const NutritionAnalysisHeader = () => {
  return (
    <NutritionAnalysisHeaderContainer>
      <DateRange
        customDropdownOptions={[
          {
            optionId: 1,
            value: "2 Weeks",
            handlerValue: 15,
          },
          {
            optionId: 2,
            value: "30 Days",
            handlerValue: 31,
          },
          {
            optionId: 3,
            value: "Date Range",
          },
        ]}
      />
    </NutritionAnalysisHeaderContainer>
  );
};
