import { PatientCGMEntryDTO } from "../../../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { buildLowReadings } from "../../../../../utils/bgm-range-readings-utils/low-readings";

type LowReadingsSeries = {
  x: Date;
  y: number;
};

export function buildLowReadingsSeries(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  minRange: number
) {
  const lowReadingsSeries: LowReadingsSeries[] = [];

  const { lowReadings } = buildLowReadings(patientCGMEntryDTOs, minRange);

  for (const lowReading of lowReadings) {
    const date = new Date(`2024-01-01T${lowReading.time}`);

    lowReadingsSeries.push({ x: date, y: lowReading.glucoseMGPerDL });
  }

  return lowReadingsSeries;
}
