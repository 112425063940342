import { useCallback, useMemo } from "react";
import { useBGMResponse } from "../../../../../context/bgm-response-context";
import { randomUUID } from "../../../../../../../../../utils/random-uuid";
import { buildPrimaryDateTimeXAxis } from "../../../../utils/bgm-charts-utils/build-primary-date-time-x-axis";
import { getInRangeNumbers } from "../../../../../../../../../utils/patient-type-utils";
import { buildPrimaryYAxis } from "../../../../utils/bgm-charts-utils/build-primary-y-axis";
import { buildAxisLabelRender } from "../../../../utils/bgm-charts-utils/build-axis-label-render";
import {
  ChartComponent,
  ColumnSeries,
  DataLabel,
  DateTime,
  Inject,
  Legend,
  SeriesCollectionDirective,
  SeriesDirective,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { BGMGlucoseTagSeries } from "../utils/build-bgm-glucose-tag-series";
import { PatientCGMEntryDTOWithBgmTags } from "../../../../utils/types";

type BGMGlucoseTagFastingChartProps = {
  fastingReadings: PatientCGMEntryDTOWithBgmTags[];
  fastingSeries: BGMGlucoseTagSeries[];
};

export const BGMGlucoseTagFastingChart = (
  props: BGMGlucoseTagFastingChartProps
) => {
  const { fastingSeries, fastingReadings } = props;

  const { patientDTO } = useBGMResponse();

  const id = useMemo(() => `bgm-glucose-tag-fasting-chart-${randomUUID()}`, []);

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);
    // Should always enter this condition, but let's be safe
    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const primaryXAxis = useMemo(() => buildPrimaryDateTimeXAxis(), []);

  const minY = useMemo(
    () =>
      Math.min(
        ...[40, ...fastingReadings.map((entry) => entry.glucoseMGPerDL)]
      ),
    [fastingReadings]
  );

  const { maxY } = useMemo(() => {
    let maxGlucose = Math.max(
      ...[40, ...fastingReadings.map((entry) => entry.glucoseMGPerDL)]
    );
    const maxY = maxGlucose > 200 ? maxGlucose + 50 : 250;

    return { maxY: Math.floor(maxY / 10) * 10 };
  }, [fastingReadings]);

  const inRangeNumbers = useMemo(() => getInRangeNumbers(patientDTO.type), [
    patientDTO,
  ]);

  const primaryYAxis = useMemo(
    () => buildPrimaryYAxis(minY, maxY, inRangeNumbers),
    [minY, maxY, inRangeNumbers]
  );

  const vAxisTicks = useMemo(() => [...inRangeNumbers, maxY], [
    inRangeNumbers,
    maxY,
  ]);

  const axisLabelRender = useMemo(() => buildAxisLabelRender(vAxisTicks), [
    vAxisTicks,
  ]);

  return (
    <div>
      <ChartComponent
        id={id}
        loaded={handleChartLoaded}
        primaryXAxis={primaryXAxis}
        primaryYAxis={primaryYAxis}
        axisLabelRender={axisLabelRender}
        height="300px"
        width="100%"
        legendSettings={{
          visible: true,
          position: "Bottom",
        }}
      >
        <Inject
          services={[DataLabel, ColumnSeries, StripLine, Legend, DateTime]}
        />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={fastingSeries}
            name="Fasting Glucose"
            xName="x"
            yName="y"
            type="Column"
            fill="#2d9bf0"
            columnWidth={0.1}
            legendShape="Circle"
            columnWidthInPixel={10}
          ></SeriesDirective>
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  );
};
