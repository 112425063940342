import { BGMGlucoseTagBreakfast } from "./bgm-glucose-tag-charts/bgm-glucose-tag-breakfast/bgm-glucose-tag-breakfast";
import { BGMGlucoseTagDinner } from "./bgm-glucose-tag-charts/bgm-glucose-tag-dinner/bgm-glucose-tag-dinner";
import { BGMGlucoseTagFasting } from "./bgm-glucose-tag-charts/bgm-glucose-tag-fasting/bgm-glucose-tag-fasting";
import { BGMGlucoseTagLunch } from "./bgm-glucose-tag-charts/bgm-glucose-tag-lunch/bgm-glucose-tag-lunch";
import { BGMGlucoseTagContainer } from "./styled-bgm-glucose-tag";

export const BGMGlucoseTag = () => {
  return (
    <BGMGlucoseTagContainer>
      <BGMGlucoseTagFasting />
      <BGMGlucoseTagBreakfast />
      <BGMGlucoseTagLunch />
      <BGMGlucoseTagDinner />
    </BGMGlucoseTagContainer>
  );
};
