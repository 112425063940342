import { PatientCGMEntryDTO } from "../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { isGlucosePointVeryHigh } from "../../../../../../../utils/glucose-point-utils/glucose-point-range-utils/is-glucose-point-very-high";
import { convertCGMEntryToReading } from "../../../../../../../utils/glucose-point-utils/convert-cgm-entry-to-reading";

export function buildVeryHighReadings(
  patientCGMEntryDTOs: PatientCGMEntryDTO[]
) {
  const veryHighReadings: PatientCGMEntryDTO[] = [];

  for (const entry of patientCGMEntryDTOs) {
    const reading = convertCGMEntryToReading(entry);

    if (isGlucosePointVeryHigh(reading)) {
      veryHighReadings.push(entry);
    }
  }

  return { veryHighReadings };
}
