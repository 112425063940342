import { DateRangeProvider } from "../../../../../components/date-range/context/date-range-context";
import { CompareLeftResponse } from "./compare-left-response/compare-left-response";
import { CompareLeftContainer } from "./styled-compare-left";

export const CompareLeft = () => {
  return (
    <CompareLeftContainer>
      <DateRangeProvider>
        <CompareLeftResponse />
      </DateRangeProvider>
    </CompareLeftContainer>
  );
};
