import { PatientCGMEntryDTO } from "../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { AgpPercentile } from "../../../agp-report/agp-report-response/utils/agp-calculate-percentile";

type PercentileCgmEntry = PatientCGMEntryDTO & { keyPercentile: string };

type GroupPercentile = {
  time: string;
  hour: string;
  cgmEntries: PercentileCgmEntry[];
};

export function groupPercentiles(
  percentiles: AgpPercentile[][]
): GroupPercentile[] {
  const concatPercentiles = percentiles[0].concat(percentiles[1]);

  const groupPercentile: GroupPercentile[] = [];

  concatPercentiles.forEach((item) => {
    const existingItem = groupPercentile.find((i) => i.time === item.time);

    if (existingItem) {
      existingItem.cgmEntries.push({
        ...item.cgmEntry,
        keyPercentile: item.keyPercentile,
      });
    } else {
      groupPercentile.push({
        time: item.time,
        hour: item.hour,
        cgmEntries: [
          {
            ...item.cgmEntry,
            keyPercentile: item.keyPercentile,
          },
        ],
      });
    }
  });

  return groupPercentile;
}
