import { PatientCGMEntryDTO } from "../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { convertCGMEntryToReading } from "../../../../../../../utils/glucose-point-utils/convert-cgm-entry-to-reading";
import { isGlucosePointLow } from "../../../../../../../utils/glucose-point-utils/glucose-point-range-utils/is-glucose-point-low";

export function buildLowReadings(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  minRange: number
) {
  const lowReadings: PatientCGMEntryDTO[] = [];

  for (const entry of patientCGMEntryDTOs) {
    const reading = convertCGMEntryToReading(entry);

    if (isGlucosePointLow(reading, minRange)) {
      lowReadings.push(entry);
    }
  }

  return { lowReadings };
}
