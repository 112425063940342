import { PatientCGMEntryDTO } from "../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientMealDTO } from "../../../../../../models/patient-meal-dtos/patient-meal-dto";
import { timeDifference } from "../../../../../../utils/glucose-point-utils/time-difference";
import {
  GroupBGMTaggedReadingsByDate,
  GroupMealsAndReadingsByDate,
  PatientCGMEntryDTOWithBgmTags,
} from "./types";

export function buildGroupMealsAndReadingsByDate(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientMeals: PatientMealDTO[]
) {
  const groupMealsAndReadingsByDate: GroupMealsAndReadingsByDate[] = [];

  for (const reading of patientCGMEntryDTOs) {
    if (reading.time) {
      const date = reading.date;

      const existingGroup = groupMealsAndReadingsByDate.find(
        (group) => group.date === date
      );

      if (existingGroup) {
        existingGroup.readings.push(reading);
        existingGroup.meals = patientMeals.filter((meal) => meal.date === date);
      } else {
        groupMealsAndReadingsByDate.push({
          date,
          readings: [reading],
          meals: patientMeals.filter((meal) => meal.date === date),
        });
      }
    }
  }

  return groupMealsAndReadingsByDate;
}

export function buildFastingReadings(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientMeals: PatientMealDTO[]
) {
  const fastingReadings: PatientCGMEntryDTOWithBgmTags[] = [];

  const groupMealsAndReadingsByDate = buildGroupMealsAndReadingsByDate(
    patientCGMEntryDTOs,
    patientMeals
  );

  for (const group of groupMealsAndReadingsByDate) {
    for (const reading of group.readings) {
      for (const meal of group.meals) {
        if (
          reading.tag &&
          reading.tag === "fasting" &&
          meal.tag &&
          meal.tag === "Breakfast" &&
          timeDifference(reading.time, meal.time) < 30
        ) {
          fastingReadings.push({
            ...reading,
            bgmTag: "Fasting",
          });
        }
      }
    }
  }

  return fastingReadings;
}

export function buildBeforeBreakfastReadings(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientMeals: PatientMealDTO[]
) {
  const beforeBreakfastReadings: PatientCGMEntryDTOWithBgmTags[] = [];

  const groupMealsAndReadingsByDate = buildGroupMealsAndReadingsByDate(
    patientCGMEntryDTOs,
    patientMeals
  );

  for (const group of groupMealsAndReadingsByDate) {
    for (const reading of group.readings) {
      for (const meal of group.meals) {
        if (
          reading.tag &&
          reading.tag === "beforeMeal" &&
          meal.tag &&
          meal.tag === "Breakfast" &&
          timeDifference(reading.time, meal.time) < 30
        ) {
          beforeBreakfastReadings.push({
            ...reading,
            bgmTag: "Before Breakfast",
          });
        }
      }
    }
  }

  return beforeBreakfastReadings;
}

export function buildAfterBreakfastReadings(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientMeals: PatientMealDTO[]
) {
  const afterBreakfastReadings: PatientCGMEntryDTOWithBgmTags[] = [];

  const groupMealsAndReadingsByDate = buildGroupMealsAndReadingsByDate(
    patientCGMEntryDTOs,
    patientMeals
  );

  for (const group of groupMealsAndReadingsByDate) {
    for (const reading of group.readings) {
      for (const meal of group.meals) {
        if (
          reading.tag &&
          reading.tag === "afterMeal" &&
          meal.tag &&
          meal.tag === "Breakfast" &&
          timeDifference(reading.time, meal.time) < 150
        ) {
          afterBreakfastReadings.push({
            ...reading,
            bgmTag: "After Breakfast",
          });
        }
      }
    }
  }

  return afterBreakfastReadings;
}

export function buildBeforeLunchReadings(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientMeals: PatientMealDTO[]
) {
  const beforeLunchReadings: PatientCGMEntryDTOWithBgmTags[] = [];

  const groupMealsAndReadingsByDate = buildGroupMealsAndReadingsByDate(
    patientCGMEntryDTOs,
    patientMeals
  );

  for (const group of groupMealsAndReadingsByDate) {
    for (const reading of group.readings) {
      for (const meal of group.meals) {
        if (
          reading.tag &&
          reading.tag === "beforeMeal" &&
          meal.tag &&
          meal.tag === "Lunch" &&
          timeDifference(reading.time, meal.time) < 30
        ) {
          beforeLunchReadings.push({
            ...reading,
            bgmTag: "Before Lunch",
          });
        }
      }
    }
  }

  return beforeLunchReadings;
}

export function buildAfterLunchReadings(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientMeals: PatientMealDTO[]
) {
  const afterLunchReadings: PatientCGMEntryDTOWithBgmTags[] = [];

  const groupMealsAndReadingsByDate = buildGroupMealsAndReadingsByDate(
    patientCGMEntryDTOs,
    patientMeals
  );

  for (const group of groupMealsAndReadingsByDate) {
    for (const reading of group.readings) {
      for (const meal of group.meals) {
        if (
          reading.tag &&
          reading.tag === "afterMeal" &&
          meal.tag &&
          meal.tag === "Lunch" &&
          timeDifference(reading.time, meal.time) < 150
        ) {
          afterLunchReadings.push({
            ...reading,
            bgmTag: "After Lunch",
          });
        }
      }
    }
  }

  return afterLunchReadings;
}

export function buildBeforeDinnerReadings(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientMeals: PatientMealDTO[]
) {
  const beforeDinnerReadings: PatientCGMEntryDTOWithBgmTags[] = [];

  const groupMealsAndReadingsByDate = buildGroupMealsAndReadingsByDate(
    patientCGMEntryDTOs,
    patientMeals
  );

  for (const group of groupMealsAndReadingsByDate) {
    for (const reading of group.readings) {
      for (const meal of group.meals) {
        if (
          reading.tag &&
          reading.tag === "beforeMeal" &&
          meal.tag &&
          meal.tag === "Dinner" &&
          timeDifference(reading.time, meal.time) < 30
        ) {
          beforeDinnerReadings.push({
            ...reading,
            bgmTag: "Before Dinner",
          });
        }
      }
    }
  }

  return beforeDinnerReadings;
}

export function buildAfterDinnerReadings(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientMeals: PatientMealDTO[]
) {
  const afterDinnerReadings: PatientCGMEntryDTOWithBgmTags[] = [];

  const groupMealsAndReadingsByDate = buildGroupMealsAndReadingsByDate(
    patientCGMEntryDTOs,
    patientMeals
  );

  for (const group of groupMealsAndReadingsByDate) {
    for (const reading of group.readings) {
      for (const meal of group.meals) {
        if (
          reading.tag &&
          reading.tag === "afterMeal" &&
          meal.tag &&
          meal.tag === "Dinner" &&
          timeDifference(reading.time, meal.time) < 150
        ) {
          afterDinnerReadings.push({
            ...reading,
            bgmTag: "After Dinner",
          });
        }
      }
    }
  }

  return afterDinnerReadings;
}

export function buildGroupBGMTaggedReadingsByDate(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientMeals: PatientMealDTO[]
) {
  const groupMealsAndReadingsByDate: GroupBGMTaggedReadingsByDate[] = [];

  const fastingReadings = buildFastingReadings(
    patientCGMEntryDTOs,
    patientMeals
  );

  const beforeBreakfastReadings = buildBeforeBreakfastReadings(
    patientCGMEntryDTOs,
    patientMeals
  );

  const afterBreakfastReadings = buildAfterBreakfastReadings(
    patientCGMEntryDTOs,
    patientMeals
  );

  const beforeLunchReadings = buildBeforeLunchReadings(
    patientCGMEntryDTOs,
    patientMeals
  );

  const afterLunchReadings = buildAfterLunchReadings(
    patientCGMEntryDTOs,
    patientMeals
  );

  const beforeDinnerReadings = buildBeforeDinnerReadings(
    patientCGMEntryDTOs,
    patientMeals
  );

  const afterDinnerReadings = buildAfterDinnerReadings(
    patientCGMEntryDTOs,
    patientMeals
  );

  const allReadings = [
    ...fastingReadings,
    ...beforeBreakfastReadings,
    ...afterBreakfastReadings,
    ...beforeLunchReadings,
    ...afterLunchReadings,
    ...beforeDinnerReadings,
    ...afterDinnerReadings,
  ];

  for (const reading of allReadings) {
    const date = reading.date;

    const existingGroup = groupMealsAndReadingsByDate.find(
      (group) => group.date === date
    );

    if (existingGroup) {
      existingGroup.readings.push(reading);
    } else {
      groupMealsAndReadingsByDate.push({
        date,
        readings: [reading],
      });
    }
  }

  return groupMealsAndReadingsByDate;
}
