import { PatientCGMEntryDTOWithBgmTags } from "../../../../utils/types";

type AfterLunchSeries = {
  x: Date;
  y: number;
  tooltip: PatientCGMEntryDTOWithBgmTags;
};

export function buildAfterLunchSeries(
  afterLunchReadings: PatientCGMEntryDTOWithBgmTags[]
) {
  const afterLunchSeries: AfterLunchSeries[] = [];

  for (const afterLunchReading of afterLunchReadings) {
    const dateString = `${afterLunchReading.date}T00:00:00`;

    afterLunchSeries.push({
      x: new Date(dateString),
      y: afterLunchReading.glucoseMGPerDL,
      tooltip: afterLunchReading,
    });
  }

  return afterLunchSeries;
}
