import { DateRange } from "../../../../../../components/date-range/date-range";

export const CompareRightResponseHeader = () => {
  return (
    <DateRange
      customDropdownOptions={[
        {
          optionId: 1,
          value: "Date Range",
        },
      ]}
      initialState="Date Range"
      width={150}
    />
  );
};
