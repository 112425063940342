import { Temporal } from "temporal-polyfill";
import { Reading } from "../../components/cgm-google-chart/utils/build-days-aggregates";
import { PatientCGMEntryDTO } from "../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";

export function convertCGMEntryToReading(
  patientCGMEntryDTO: PatientCGMEntryDTO
): Reading {
  return {
    glucose: patientCGMEntryDTO.glucoseMGPerDL,
    source: patientCGMEntryDTO.source,
    time: Temporal.PlainTime.from(patientCGMEntryDTO.time),
    tag: patientCGMEntryDTO.tag,
  };
}
