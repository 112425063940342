import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import {
  CompareAGPBody,
  CompareAGPCard,
  CompareAGPContainer,
  CompareAGPHeader,
} from "../../../common-styles/styled-compare-agp/styled-compare-agp";
import { useLoadedCompareLeft } from "../../context/loaded-compare-left-context";
import { CompareLeftResponeAGPGraph } from "./compare-left-response-agp-graph";

export const CompareLeftResponseAgp = () => {
  const { groupCgmEntriesByDate } = useLoadedCompareLeft();

  if (groupCgmEntriesByDate.length === 0) {
    return (
      <CompareAGPContainer>
        <CompareAGPCard>
          <CompareAGPHeader>
            <p>AGP Graph</p>
          </CompareAGPHeader>
          <CompareAGPBody $isEmpty>
            <EmptyState
              button={<></>}
              hasHeader={false}
              hasSearch={false}
              text="No Data Within the selected dates"
              title=""
            />
          </CompareAGPBody>
        </CompareAGPCard>
      </CompareAGPContainer>
    );
  }

  if (groupCgmEntriesByDate.length > 0 && groupCgmEntriesByDate.length < 14) {
    return (
      <CompareAGPContainer>
        <CompareAGPCard>
          <CompareAGPHeader>
            <p>AGP Graph</p>
          </CompareAGPHeader>
          <CompareAGPBody $isEmpty>
            <EmptyState
              button={<></>}
              hasHeader={false}
              hasSearch={false}
              text="Please select a date range with 14 Days or more days!"
              title=""
            />
          </CompareAGPBody>
        </CompareAGPCard>
      </CompareAGPContainer>
    );
  }

  return (
    <CompareAGPContainer>
      <CompareAGPCard>
        <CompareAGPHeader>
          <p>AGP Graph</p>
        </CompareAGPHeader>
        <CompareAGPBody $isEmpty={false}>
          <CompareLeftResponeAGPGraph />
        </CompareAGPBody>
      </CompareAGPCard>
    </CompareAGPContainer>
  );
};
