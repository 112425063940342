import { AxisModel } from "@syncfusion/ej2-react-charts";

export function buildPrimaryXAxis(min: Date, max: Date): AxisModel {
  return {
    title: "Time",
    valueType: "DateTime",
    edgeLabelPlacement: "Shift",
    labelFormat: "hh:mm",
    majorGridLines: {
      color: "transparent",
      width: 0,
    },
    interval: 3,
    minimum: min,
    maximum: max,
  };
}
