import { AxisModel } from "@syncfusion/ej2-react-charts";

export function buildPrimaryYAxis(
  minY: number,
  maxY: number,
  inRangeNumbers: number[]
): AxisModel {
  return {
    title: "BG Level (mg/dl)",
    edgeLabelPlacement: "Shift",
    enableAutoIntervalOnZooming: true,
    minimum: minY,
    maximum: maxY,
    maximumLabels: 3,
    interval: 10,
    majorGridLines: {
      color: "transparent",
      width: 0,
    },
    majorTickLines: {
      width: 0,
    },
    stripLines: [
      {
        start: minY,
        end: inRangeNumbers[0],
        color: "transparent",
        opacity: 0.1,
        visible: true,
      },
      {
        start: inRangeNumbers[0],
        end: inRangeNumbers[0] + 1,
        color: "#f01818",
        opacity: 0.5,
        dashArray: "10,5",
        size: 2,
        sizeType: "Pixel",
      },
      {
        start: inRangeNumbers[0],
        end: inRangeNumbers[1],
        color: "transparent",
        opacity: 0.1,
        visible: true,
      },
      {
        start: inRangeNumbers[1],
        end: inRangeNumbers[1] + 1,
        color: "#f01818",
        opacity: 0.5,
        dashArray: "10,5",
        size: 2,
        sizeType: "Pixel",
      },
      {
        start: inRangeNumbers[1],
        end: maxY,
        color: "transparent",
        opacity: 0.1,
        visible: true,
      },
    ],
  };
}
