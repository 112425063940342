import {
  AGPReportResponseGlucoseStatisticsBody,
  AGPReportResponseGlucoseStatisticsBodyItem,
  AGPReportResponseGlucoseStatisticsBodyItemTitle,
} from "../../../../../single-patient/bgl-analysis/agp-report/agp-report-response/agp-report-response-cards/agp-report-response-glucose-statistics/styled-agp-report-response-glucose-statistics";
import {
  AGPReportResponseTimeRangesChartContainer,
  AGPReportResponseTimeRangesChartItem,
} from "../../../../../single-patient/bgl-analysis/agp-report/agp-report-response/agp-report-response-cards/agp-report-response-time-ranges/agp-report-response-time-ranges-chart/styled-agp-report-response-time-ranges-chart";
import {
  AGPReportResponseTimeRangesDetailsContainer,
  AGPReportResponseTimeRangesDetailsItem,
} from "../../../../../single-patient/bgl-analysis/agp-report/agp-report-response/agp-report-response-cards/agp-report-response-time-ranges/agp-report-response-time-ranges-details/styled-agp-report-response-time-ranges-details";
import {
  AGPReportResponseTimeRangesBody,
  AGPReportResponseTimeRangesContainer,
  AGPReportResponseTimeRangesHeader,
} from "../../../../../single-patient/bgl-analysis/agp-report/agp-report-response/agp-report-response-cards/agp-report-response-time-ranges/styled-agp-report-response-time-ranges";
import { ImmediateAttentionPatientsSidebarGlucoseTabContainer } from "./styled-immediate-attention-patients-sidebar-glucose-tab";

export const ImmediateAttentionPatientsSidebarGlucoseTab = () => {
  return (
    <ImmediateAttentionPatientsSidebarGlucoseTabContainer>
      <p className="caption">Last 14 days</p>
      <AGPReportResponseGlucoseStatisticsBody>
        <AGPReportResponseGlucoseStatisticsBodyItem>
          <AGPReportResponseGlucoseStatisticsBodyItemTitle>
            Average Glucose
          </AGPReportResponseGlucoseStatisticsBodyItemTitle>
          <span>
            154.8 <span>mg/dl</span>
          </span>
        </AGPReportResponseGlucoseStatisticsBodyItem>
        <AGPReportResponseGlucoseStatisticsBodyItem>
          <AGPReportResponseGlucoseStatisticsBodyItemTitle className="shrink-agp-report-title">
            GMI
            <i className="material-symbols-outlined">info</i>
            <span className="shrinked-agp-report-span">
              Glucose Management Indicator (Approximate A1C level based in
              average CGM Glucose level.)
            </span>
          </AGPReportResponseGlucoseStatisticsBodyItemTitle>
          <span>7%</span>
        </AGPReportResponseGlucoseStatisticsBodyItem>
      </AGPReportResponseGlucoseStatisticsBody>
      <AGPReportResponseTimeRangesContainer>
        <AGPReportResponseTimeRangesHeader>
          <p>Time Ranges</p>
        </AGPReportResponseTimeRangesHeader>
        <AGPReportResponseTimeRangesBody>
          <AGPReportResponseTimeRangesChartContainer>
            <AGPReportResponseTimeRangesChartItem
              className="very-high"
              $height={`10.1%`}
            />
            <AGPReportResponseTimeRangesChartItem
              className="high"
              $height={`26.5%`}
            />
            <AGPReportResponseTimeRangesChartItem
              className="target"
              $height={`60.1%`}
            />
            <AGPReportResponseTimeRangesChartItem
              className="low"
              $height={`${3.2}%`}
            />
            <AGPReportResponseTimeRangesChartItem
              className="very-low"
              $height={`${0.1}%`}
            />
          </AGPReportResponseTimeRangesChartContainer>
          <AGPReportResponseTimeRangesDetailsContainer>
            <AGPReportResponseTimeRangesDetailsItem $height={`${10.1}%`}>
              <p>
                Very High <span>{`>`}250 mg/dl</span>
              </p>
              <strong>{10.1}%</strong>
            </AGPReportResponseTimeRangesDetailsItem>
            <AGPReportResponseTimeRangesDetailsItem $height={`${26.5}%`}>
              <p>
                High <span>181 - 250 mg/dl</span>
              </p>
              <strong>{26.5}%</strong>
            </AGPReportResponseTimeRangesDetailsItem>
            <AGPReportResponseTimeRangesDetailsItem $height={`${60.1}%`}>
              <p>
                Target <span>70 - 180 mg/dl</span>
              </p>
              <strong>{60.1}%</strong>
            </AGPReportResponseTimeRangesDetailsItem>
            <AGPReportResponseTimeRangesDetailsItem $height={`${3.2}%`}>
              <p>
                Low <span>54 - 69 mg/dl</span>
              </p>
              <strong>{3.2}%</strong>
            </AGPReportResponseTimeRangesDetailsItem>
            <AGPReportResponseTimeRangesDetailsItem $height={`${0.1}%`}>
              <p>
                Very Low <span>{`<`} 54</span>
              </p>
              <strong>{0.1}%</strong>
            </AGPReportResponseTimeRangesDetailsItem>
          </AGPReportResponseTimeRangesDetailsContainer>
        </AGPReportResponseTimeRangesBody>
      </AGPReportResponseTimeRangesContainer>
    </ImmediateAttentionPatientsSidebarGlucoseTabContainer>
  );
};
