import { useCallback, useMemo } from "react";
import { randomUUID } from "../../../../../../../../utils/random-uuid";
import { useBGMResponse } from "../../../../context/bgm-response-context";
import { buildPrimaryXAxis } from "./utils/build-primary-x-axis";
import { getInRangeNumbers } from "../../../../../../../../utils/patient-type-utils";
import { buildPrimaryYAxis } from "../../../utils/bgm-charts-utils/build-primary-y-axis";
import { buildAxisLabelRender } from "../../../utils/bgm-charts-utils/build-axis-label-render";
import { buildVeryHighReadingsSeries } from "./utils/series/build-very-high-readings-series";
import { buildTargetReadingsSeries } from "./utils/series/build-target-readings-series";
import { buildLowReadingsSeries } from "./utils/series/build-low-readings-series";
import { buildVeryLowReadingsSeries } from "./utils/series/build-very-low-readings-series";
import {
  ChartComponent,
  DataLabel,
  DateTime,
  Inject,
  Legend,
  MarkerSettingsModel,
  ScatterSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { buildHighReadingsSeries } from "./utils/series/build-high-readings-series";
import { BGMTimeOfDayChartContainer } from "./styled-bgm-time-of-day-chart";

export const BGMTimeOfDayChart = () => {
  const { patientCGMEntryDTOs, patientDTO } = useBGMResponse();

  const id = useMemo(() => `bgm-time-of-day-chart-${randomUUID()}`, []);

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);
    // Should always enter this condition, but let's be safe
    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const primaryXAxis = useMemo(
    () =>
      buildPrimaryXAxis(
        new Date(`2024-01-01T00:00:00`),
        new Date(`2024-01-02T00:00:00`)
      ),
    []
  );

  const minY = useMemo(
    () =>
      Math.min(
        ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
      ),
    [patientCGMEntryDTOs]
  );

  const { maxY } = useMemo(() => {
    let maxGlucose = Math.max(
      ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
    );
    const maxY = maxGlucose > 200 ? maxGlucose + 50 : 250;

    return { maxY: Math.floor(maxY / 10) * 10 };
  }, [patientCGMEntryDTOs]);

  const inRangeNumbers = useMemo(() => getInRangeNumbers(patientDTO.type), [
    patientDTO,
  ]);

  const primaryYAxis = useMemo(
    () => buildPrimaryYAxis(minY, maxY, inRangeNumbers),
    [minY, maxY, inRangeNumbers]
  );

  const vAxisTicks = useMemo(() => [...inRangeNumbers, maxY], [
    inRangeNumbers,
    maxY,
  ]);

  const axisLabelRender = useMemo(() => buildAxisLabelRender(vAxisTicks), [
    vAxisTicks,
  ]);

  const inRangeNumber = getInRangeNumbers(patientDTO.type);
  const maxRange = inRangeNumber[1];
  const minRange = inRangeNumber[0];

  const veryHighReadingsSeries = useMemo(
    () => buildVeryHighReadingsSeries(patientCGMEntryDTOs),
    [patientCGMEntryDTOs]
  );

  const highReadingsSeries = useMemo(
    () => buildHighReadingsSeries(patientCGMEntryDTOs, maxRange),
    [patientCGMEntryDTOs, maxRange]
  );

  const targetReadingsSeries = useMemo(
    () => buildTargetReadingsSeries(patientCGMEntryDTOs, patientDTO),
    [patientCGMEntryDTOs, patientDTO]
  );

  const lowReadingsSeries = useMemo(
    () => buildLowReadingsSeries(patientCGMEntryDTOs, minRange),
    [patientCGMEntryDTOs, minRange]
  );

  const veryLowReadingsSeries = useMemo(
    () => buildVeryLowReadingsSeries(patientCGMEntryDTOs),
    [patientCGMEntryDTOs]
  );

  const marker: MarkerSettingsModel = {
    width: 9,
    height: 9,
    shape: "Circle",
  };

  return (
    <BGMTimeOfDayChartContainer>
      <ChartComponent
        id={id}
        loaded={handleChartLoaded}
        primaryXAxis={primaryXAxis}
        primaryYAxis={primaryYAxis}
        axisLabelRender={axisLabelRender}
        height="350px"
        width="100%"
        legendSettings={{ visible: true, position: "Bottom" }}
      >
        <Inject
          services={[DataLabel, StripLine, DateTime, Legend, ScatterSeries]}
        />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={veryHighReadingsSeries}
            xName="x"
            yName="y"
            type="Scatter"
            fill="#fc881b"
            name="Very High"
            legendShape="Circle"
            marker={marker}
          ></SeriesDirective>
          <SeriesDirective
            dataSource={highReadingsSeries}
            xName="x"
            yName="y"
            type="Scatter"
            fill="#f1d98b"
            name="High"
            legendShape="Circle"
            marker={marker}
          ></SeriesDirective>
          <SeriesDirective
            dataSource={targetReadingsSeries}
            xName="x"
            yName="y"
            type="Scatter"
            fill="#41a56c"
            name="Target"
            legendShape="Circle"
            marker={marker}
          ></SeriesDirective>
          <SeriesDirective
            dataSource={lowReadingsSeries}
            xName="x"
            yName="y"
            type="Scatter"
            fill="#d73f1b"
            name="Low"
            legendShape="Circle"
            marker={marker}
          ></SeriesDirective>
          <SeriesDirective
            dataSource={veryLowReadingsSeries}
            xName="x"
            yName="y"
            type="Scatter"
            fill="#6d2f33"
            name="Very Low"
            legendShape="Circle"
            marker={marker}
          ></SeriesDirective>
        </SeriesCollectionDirective>
      </ChartComponent>
    </BGMTimeOfDayChartContainer>
  );
};
