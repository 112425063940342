import { useMemo } from "react";
import { useBGMResponse } from "../../../../../context/bgm-response-context";
import { BGMCardFooter, BGMCardHeader } from "../../../styles";
import {
  buildAfterBreakfastReadings,
  buildBeforeBreakfastReadings,
} from "../../../../utils/bgm-response-utils";
import { roundTo1DecimalPlace } from "../../../../../../../../../utils/math-utils";
import { BGMGlucoseTagBreakfastChart } from "./bgm-glucose-tag-breakfast-chart";
import { EmptyState } from "../../../../../../../../../components/empty-state/empty-state";
import { BGMGlucoseTagCard } from "../../styled-bgm-glucose-tag";

export const BGMGlucoseTagBreakfast = () => {
  const { patientMeals, patientCGMEntryDTOs } = useBGMResponse();

  const {
    beforeBreakfastReadings,
    afterBreakfastReadings,
    breakfastReadings,
  } = useMemo(() => {
    const beforeBreakfastReadings = buildBeforeBreakfastReadings(
      patientCGMEntryDTOs,
      patientMeals
    );

    const afterBreakfastReadings = buildAfterBreakfastReadings(
      patientCGMEntryDTOs,
      patientMeals
    );

    const breakfastReadings = [
      ...beforeBreakfastReadings,
      ...afterBreakfastReadings,
    ];

    return {
      beforeBreakfastReadings,
      afterBreakfastReadings,
      breakfastReadings,
    };
  }, [patientCGMEntryDTOs, patientMeals]);

  const {
    averageBreakfastGlucose,
    averageBeforeBreakfastGlucose,
    averageAfterBreakfastGlucose,
  } = useMemo(() => {
    const averageBreakfastGlucose =
      breakfastReadings.reduce((sum, reading) => {
        return sum + reading.glucoseMGPerDL;
      }, 0) / breakfastReadings.length;

    const averageBeforeBreakfastGlucose =
      beforeBreakfastReadings.reduce((sum, reading) => {
        return sum + reading.glucoseMGPerDL;
      }, 0) / beforeBreakfastReadings.length;

    const averageAfterBreakfastGlucose =
      afterBreakfastReadings.reduce((sum, reading) => {
        return sum + reading.glucoseMGPerDL;
      }, 0) / afterBreakfastReadings.length;

    return {
      averageBreakfastGlucose,
      averageBeforeBreakfastGlucose,
      averageAfterBreakfastGlucose,
    };
  }, [breakfastReadings, beforeBreakfastReadings, afterBreakfastReadings]);

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <BGMGlucoseTagCard>
        <BGMCardHeader>
          <p>Before & After Breakfast Glucose (mg/dl)</p>
        </BGMCardHeader>
        <div>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </div>
      </BGMGlucoseTagCard>
    );
  }

  return (
    <BGMGlucoseTagCard>
      <BGMCardHeader>
        <p>Before & After Breakfast Glucose (mg/dl)</p>
        <span>
          Total Average: {roundTo1DecimalPlace(averageBreakfastGlucose)} mg/dl
        </span>
      </BGMCardHeader>
      <BGMGlucoseTagBreakfastChart
        beforeBreakfastReadings={beforeBreakfastReadings}
        afterBreakfastReadings={afterBreakfastReadings}
        breakfastReadings={breakfastReadings}
      />
      <BGMCardFooter>
        <div>
          <p>Avg Before Breakfast Glucose</p>
          <span>
            {roundTo1DecimalPlace(averageBeforeBreakfastGlucose)} mg/dl
          </span>
        </div>
        <div>
          <p>Avg After Breakfast Glucose</p>
          <span>
            {roundTo1DecimalPlace(averageAfterBreakfastGlucose)} mg/dl
          </span>
        </div>
      </BGMCardFooter>
    </BGMGlucoseTagCard>
  );
};
