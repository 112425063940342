import { PatientCGMEntryDTOWithBgmTags } from "../../../../utils/types";

type BeforeLunchSeries = {
  x: Date;
  y: number;
  tooltip: PatientCGMEntryDTOWithBgmTags;
};

export function buildBeforeLunchSeries(
  beforeLunchReadings: PatientCGMEntryDTOWithBgmTags[]
) {
  const beforeLunchSeries: BeforeLunchSeries[] = [];

  for (const beforeLunchReading of beforeLunchReadings) {
    const dateString = `${beforeLunchReading.date}T00:00:00`;

    beforeLunchSeries.push({
      x: new Date(dateString),
      y: beforeLunchReading.glucoseMGPerDL,
      tooltip: beforeLunchReading,
    });
  }

  return beforeLunchSeries;
}
