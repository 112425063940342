import { useMemo } from "react";
import { buildDaysAggregatesReadingsWithTimeDifference } from "../../../../../../utils/glucose-point-utils/build-readings-with-time-differance-value";
import { calculateReadingsTimeDiffrenceTotal } from "../../../../../../utils/glucose-point-utils/calculate-readings-time-differance-total";
import { convertCGMEntriesToReadings } from "../../../../../../utils/glucose-point-utils/convert-cgm-entries-to-readings";
import { roundTo1DecimalPlace } from "../../../../../../utils/math-utils";
import { useDateRange } from "../../../../../../components/date-range/context/date-range-context";
import { useLoadedCompareRight } from "../context/loaded-compare-right-context";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import {
  CompareGMITitleIcon,
  CompareGMITitleInfo,
  CompareGMITitleInfoContainer,
  CompareStatisticsBody,
  CompareStatisticsBodyItem,
  CompareStatisticsBodyItemGMITitle,
  CompareStatisticsBodyItemSubContainer,
  CompareStatisticsBodyItemTitle,
  CompareStatisticsContainer,
  CompareStatisticsHeader,
} from "../../common-styles/styled-compare-statistics";

export const CompareRightResponseStatistics = () => {
  const {
    patientCGMEntryDTOs,
    groupCgmEntriesByDate,
  } = useLoadedCompareRight();

  const { selectedDates } = useDateRange();

  const { average } = useMemo(() => {
    let total = 0;

    for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
      const glucoseReading = patientCGMEntryDTO.glucoseMGPerDL;

      total += glucoseReading;
    }

    const average = roundTo1DecimalPlace(total / patientCGMEntryDTOs.length);

    return { average };
  }, [patientCGMEntryDTOs]);

  const { gmi, warningGMIMessage } = useMemo(() => {
    let warningGMIMessage: string = "";

    // 3.31 + 0.02392 * average
    const glycatedAlbumin = 3.31;
    const glycatedProteins = 0.02392;

    if (groupCgmEntriesByDate.length < 14 || selectedDates.count < 14) {
      warningGMIMessage =
        "Can't be calculated, date range is less than 14 days!";
    }

    const glycatedProteinsByAverage = glycatedProteins * average;
    const gmi = glycatedAlbumin + glycatedProteinsByAverage;

    return { gmi, warningGMIMessage };
  }, [selectedDates.count, average, groupCgmEntriesByDate]);

  const { cgmActivePercentage } = useMemo(() => {
    const cgmReadingsInSelectedDateRange = groupCgmEntriesByDate
      .map((item) => item.readings)
      .flat();

    const convertCgmReadingsInSelectedDateRangeToReadings = convertCGMEntriesToReadings(
      cgmReadingsInSelectedDateRange
    );

    const readingsWithTimeDifferenceValue = buildDaysAggregatesReadingsWithTimeDifference(
      convertCgmReadingsInSelectedDateRangeToReadings
    );

    const readingsTimeDifferanceTotal = calculateReadingsTimeDiffrenceTotal(
      readingsWithTimeDifferenceValue
    );

    const totalHoursInSelectedDates = selectedDates.count * 24;
    const totalMinutesInSelectedDates = totalHoursInSelectedDates * 60;

    const cgmActivePercentage = roundTo1DecimalPlace(
      (readingsTimeDifferanceTotal / totalMinutesInSelectedDates) * 100
    );

    return { cgmActivePercentage };
  }, [groupCgmEntriesByDate, selectedDates.count]);

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <CompareStatisticsContainer>
        <CompareStatisticsHeader>
          <p>Glucose Statistics</p>
        </CompareStatisticsHeader>
        <CompareStatisticsBody>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </CompareStatisticsBody>
      </CompareStatisticsContainer>
    );
  }

  return (
    <CompareStatisticsContainer>
      <CompareStatisticsHeader>
        <p>Glucose Statistics</p>
      </CompareStatisticsHeader>
      <CompareStatisticsBody>
        <CompareStatisticsBodyItem $topListItem>
          <CompareStatisticsBodyItemSubContainer>
            <CompareStatisticsBodyItemTitle>
              Average Glucose
            </CompareStatisticsBodyItemTitle>
            <span>
              {average} <span>mg/dl</span>
            </span>
          </CompareStatisticsBodyItemSubContainer>
          <CompareStatisticsBodyItemSubContainer>
            <CompareStatisticsBodyItemGMITitle>
              <p>GMI (Estimated HbA1c)</p>
              <CompareGMITitleInfoContainer>
                <CompareGMITitleIcon className="material-symbols-outlined">
                  info
                </CompareGMITitleIcon>
                <CompareGMITitleInfo>
                  <p>Glucose Management Indicator (GMI)</p>
                  <span>
                    Approximate A1C level based in average CGM Glucose level.
                  </span>
                </CompareGMITitleInfo>
              </CompareGMITitleInfoContainer>
            </CompareStatisticsBodyItemGMITitle>
            {selectedDates.count < 14 || groupCgmEntriesByDate.length < 14 ? (
              <strong>{warningGMIMessage}</strong>
            ) : (
              <span>{roundTo1DecimalPlace(gmi)}%</span>
            )}
          </CompareStatisticsBodyItemSubContainer>
        </CompareStatisticsBodyItem>
        <CompareStatisticsBodyItem>
          <div>
            <CompareStatisticsBodyItemTitle>
              <span>% Time CGM is Active: {cgmActivePercentage}%</span>
            </CompareStatisticsBodyItemTitle>
          </div>
        </CompareStatisticsBodyItem>
      </CompareStatisticsBody>
    </CompareStatisticsContainer>
  );
};
