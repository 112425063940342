import { PatientCGMEntryDTO } from "../../../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { buildHighReadings } from "../../../../../utils/bgm-range-readings-utils/high-readings";

type HighReadingsSeries = {
  x: Date;
  y: number;
};

export function buildHighReadingsSeries(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  maxRange: number
) {
  const highReadingsSeries: HighReadingsSeries[] = [];

  const { highReadings } = buildHighReadings(patientCGMEntryDTOs, maxRange);

  for (const highReading of highReadings) {
    const date = new Date(`2024-01-01T${highReading.time}`);

    highReadingsSeries.push({ x: date, y: highReading.glucoseMGPerDL });
  }

  return highReadingsSeries;
}
