import { PatientCGMEntryDTO } from "../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { convertCGMEntryToReading } from "../../../../../../../utils/glucose-point-utils/convert-cgm-entry-to-reading";
import { isGlucosePointHigh } from "../../../../../../../utils/glucose-point-utils/glucose-point-range-utils/is-glucose-point-high";

export function buildHighReadings(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  maxRange: number
) {
  const highReadings: PatientCGMEntryDTO[] = [];

  for (const entry of patientCGMEntryDTOs) {
    const reading = convertCGMEntryToReading(entry);

    if (isGlucosePointHigh(reading, maxRange)) {
      highReadings.push(entry);
    }
  }

  return { highReadings };
}
