import { useMemo } from "react";
import { useBGMResponse } from "../../../../../context/bgm-response-context";
import { BGMCardHeader } from "../../../styles";
import { BGMGlucoseTagFastingChart } from "./bgm-glucose-tag-fasting-chart";
import { buildFastingReadings } from "../../../../utils/bgm-response-utils";
import { buildBGMGlucoseTagSeries } from "../utils/build-bgm-glucose-tag-series";
import { roundTo1DecimalPlace } from "../../../../../../../../../utils/math-utils";
import { EmptyState } from "../../../../../../../../../components/empty-state/empty-state";
import { BGMGlucoseTagCard } from "../../styled-bgm-glucose-tag";

export const BGMGlucoseTagFasting = () => {
  const { patientMeals, patientCGMEntryDTOs } = useBGMResponse();

  const fastingReadings = useMemo(
    () => buildFastingReadings(patientCGMEntryDTOs, patientMeals),
    [patientCGMEntryDTOs, patientMeals]
  );

  const { fastingSeries, averageFastingGlucose } = useMemo(() => {
    const averageFastingGlucose =
      fastingReadings.reduce((sum, reading) => {
        return sum + reading.glucoseMGPerDL;
      }, 0) / fastingReadings.length;

    const fastingSeries = buildBGMGlucoseTagSeries(fastingReadings);

    return { fastingSeries, averageFastingGlucose };
  }, [fastingReadings]);

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <BGMGlucoseTagCard>
        <BGMCardHeader>
          <p>Fasting Glucose (mg/dl)</p>
        </BGMCardHeader>
        <div>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </div>
      </BGMGlucoseTagCard>
    );
  }

  return (
    <BGMGlucoseTagCard>
      <BGMCardHeader>
        <p>Fasting Glucose (mg/dl)</p>
        <span>
          Total Average: {roundTo1DecimalPlace(averageFastingGlucose)} mg/dl
        </span>
      </BGMCardHeader>
      <BGMGlucoseTagFastingChart
        fastingReadings={fastingReadings}
        fastingSeries={fastingSeries}
      />
    </BGMGlucoseTagCard>
  );
};
