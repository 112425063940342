import { AxisModel } from "@syncfusion/ej2-react-charts";

export function buildPrimaryDateTimeXAxis(): AxisModel {
  return {
    valueType: "DateTime",
    intervalType: "Days",
    edgeLabelPlacement: "Shift",
    labelFormat: "dd-MMM",
    majorGridLines: {
      color: "transparent",
      width: 0,
    },
  };
}
