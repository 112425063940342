import { CompareRight } from "./compare-right/compare-right";
import { CompareLeft } from "./compare-left/compare-left";
import { CompareContainer } from "./styled-compare";

export const Compare = () => {
  return (
    <CompareContainer>
      <CompareLeft />
      <CompareRight />
    </CompareContainer>
  );
};
