import { DateRange } from "../../../../../components/date-range/date-range";
import { BGMResponseHeaderContainer } from "./styled-bgm-response-header";

export const BgmResponseHeader = () => {
  return (
    <BGMResponseHeaderContainer>
      <DateRange />
    </BGMResponseHeaderContainer>
  );
};
