import { useMemo } from "react";
import { useBGMResponse } from "../../../../context/bgm-response-context";
import {
  BGMGlucoseBGDetailsContainer,
  BGMGlucoseBGDetailsItem,
} from "./styled-bgm-glucose-bg-details";
import { getInRangeNumbers } from "../../../../../../../../utils/patient-type-utils";
import { calculateGlucoseBGRangesPercentage } from "../utils/glucose-bg-ranges-percentage-utils";

export const GlucoseBGDetails = () => {
  const { patientCGMEntryDTOs, patientDTO } = useBGMResponse();

  const inRangeNumber = getInRangeNumbers(patientDTO.type);
  const maxRange = inRangeNumber[1];
  const minRange = inRangeNumber[0];

  const { veryHigh, high, low, target, veryLow } = useMemo(
    () =>
      calculateGlucoseBGRangesPercentage(
        patientCGMEntryDTOs,
        minRange,
        maxRange,
        patientDTO
      ),
    [patientCGMEntryDTOs, minRange, maxRange, patientDTO]
  );

  return (
    <BGMGlucoseBGDetailsContainer>
      <BGMGlucoseBGDetailsItem $height={`${veryHigh}%`}>
        <p>
          Very High <span>{`>`}250 mg/dl</span>
        </p>
        <strong>{veryHigh}%</strong>
      </BGMGlucoseBGDetailsItem>
      <BGMGlucoseBGDetailsItem $height={`${high}%`}>
        <p>
          High <span>181 - 250 mg/dl</span>
        </p>
        <strong>{high}%</strong>
      </BGMGlucoseBGDetailsItem>
      <BGMGlucoseBGDetailsItem $height={`${target}%`}>
        <p>
          Target <span>70 - 180 mg/dl</span>
        </p>
        <strong>{target}%</strong>
      </BGMGlucoseBGDetailsItem>
      <BGMGlucoseBGDetailsItem $height={`${low}%`}>
        <p>
          Low <span>54 - 69 mg/dl</span>
        </p>
        <strong>{low}%</strong>
      </BGMGlucoseBGDetailsItem>
      <BGMGlucoseBGDetailsItem $height={`${veryLow}%`}>
        <p>
          Very Low <span>{`<`} 54</span>
        </p>
        <strong>{veryLow}%</strong>
      </BGMGlucoseBGDetailsItem>
    </BGMGlucoseBGDetailsContainer>
  );
};
