import { DateRangeProvider } from "../../../../../components/date-range/context/date-range-context";
import { CompareRightResponse } from "./compare-right-response/compare-right-response";
import { CompareRightContainer } from "./styled-compare-right";

export const CompareRight = () => {
  return (
    <CompareRightContainer>
      <DateRangeProvider>
        <CompareRightResponse />
      </DateRangeProvider>
    </CompareRightContainer>
  );
};
