import { useMemo } from "react";
import { useBGMResponse } from "../../../../../context/bgm-response-context";
import { BGMCardFooter, BGMCardHeader } from "../../../styles";
import {
  buildAfterDinnerReadings,
  buildBeforeDinnerReadings,
} from "../../../../utils/bgm-response-utils";
import { roundTo1DecimalPlace } from "../../../../../../../../../utils/math-utils";
import { BGMGlucoseTagDinnerChart } from "./bgm-glucose-tag-dinner-chart";
import { EmptyState } from "../../../../../../../../../components/empty-state/empty-state";
import { BGMGlucoseTagCard } from "../../styled-bgm-glucose-tag";

export const BGMGlucoseTagDinner = () => {
  const { patientMeals, patientCGMEntryDTOs } = useBGMResponse();

  const {
    beforeDinnerReadings,
    afterDinnerReadings,
    dinnerReadings,
  } = useMemo(() => {
    const beforeDinnerReadings = buildBeforeDinnerReadings(
      patientCGMEntryDTOs,
      patientMeals
    );

    const afterDinnerReadings = buildAfterDinnerReadings(
      patientCGMEntryDTOs,
      patientMeals
    );

    const dinnerReadings = [...beforeDinnerReadings, ...afterDinnerReadings];

    return {
      beforeDinnerReadings,
      afterDinnerReadings,
      dinnerReadings,
    };
  }, [patientCGMEntryDTOs, patientMeals]);

  const {
    averageDinnerGlucose,
    averageBeforeDinnerGlucose,
    averageAfterDinnerGlucose,
  } = useMemo(() => {
    const averageDinnerGlucose =
      dinnerReadings.reduce((sum, reading) => {
        return sum + reading.glucoseMGPerDL;
      }, 0) / dinnerReadings.length;

    const averageBeforeDinnerGlucose =
      beforeDinnerReadings.reduce((sum, reading) => {
        return sum + reading.glucoseMGPerDL;
      }, 0) / beforeDinnerReadings.length;

    const averageAfterDinnerGlucose =
      afterDinnerReadings.reduce((sum, reading) => {
        return sum + reading.glucoseMGPerDL;
      }, 0) / afterDinnerReadings.length;

    return {
      averageDinnerGlucose,
      averageBeforeDinnerGlucose,
      averageAfterDinnerGlucose,
    };
  }, [dinnerReadings, beforeDinnerReadings, afterDinnerReadings]);

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <BGMGlucoseTagCard>
        <BGMCardHeader>
          <p>Before & After Dinner Glucose (mg/dl)</p>
        </BGMCardHeader>
        <div>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </div>
      </BGMGlucoseTagCard>
    );
  }

  return (
    <BGMGlucoseTagCard>
      <BGMCardHeader>
        <p>Before & After Dinner Glucose (mg/dl)</p>
        <span>
          Total Average: {roundTo1DecimalPlace(averageDinnerGlucose)} mg/dl
        </span>
      </BGMCardHeader>
      <BGMGlucoseTagDinnerChart
        dinnerReadings={dinnerReadings}
        beforeDinnerReadings={beforeDinnerReadings}
        afterDinnerReadings={afterDinnerReadings}
      />
      <BGMCardFooter>
        <div>
          <p>Avg Before Dinner Glucose</p>
          <span>{roundTo1DecimalPlace(averageBeforeDinnerGlucose)} mg/dl</span>
        </div>
        <div>
          <p>Avg After Dinner Glucose</p>
          <span>{roundTo1DecimalPlace(averageAfterDinnerGlucose)} mg/dl</span>
        </div>
      </BGMCardFooter>
    </BGMGlucoseTagCard>
  );
};
