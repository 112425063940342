import { useNavigate, useParams } from "react-router-dom";
import { useBglAnalysis } from "../../context/loadable-bgl-analysis-context";
import { useBuildDayAggregates } from "../../../../../components/cgm-google-chart/hooks/use-build-day-aggregates";
import { useCallback, useEffect, useState } from "react";
import { Pagination } from "../../../../../components/pagination/pagination";
import {
  DailyChartPaginationContainer,
  LoadedDailyChartsContainer,
} from "../../daily-analysis/daily-charts/styled-daily-charts";
import { DailyFixedSummaryChartContainer } from "./daily-fixed-summary-chart-container/daily-fixed-summary-chart-container";
import { DateRange } from "../../../../../components/date-range/date-range";
import { useDateRange } from "../../../../../components/date-range/context/date-range-context";

export const DailyFixedSummaryCharts = () => {
  const { page, id } = useParams();

  const { patientDTO } = useBglAnalysis();

  const { temporalStartDate, temporalEndDate } = useDateRange();
  const navigate = useNavigate();

  useEffect(() => {
    if (temporalStartDate && temporalEndDate) {
      navigate(`/patients/${id}/bgl-analysis/daily-fixed-summary/1`);
    }
  }, [id, navigate, temporalStartDate, temporalEndDate]);

  const { daysAggregates } = useBuildDayAggregates(
    patientDTO,
    false,
    temporalStartDate,
    temporalEndDate
  );

  const [tableRowsPerPage, setTableRowsPerPage] = useState(8);

  const indexOfLastEntry = Number(page) * tableRowsPerPage;
  const indexOfFirstEntry = indexOfLastEntry - tableRowsPerPage;

  const currentPageDaysAggregates = daysAggregates.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const calculateDailyChartZIndex = useCallback(
    (index: number) => {
      const minZIndex = 5;

      return currentPageDaysAggregates.length + minZIndex - index;
    },
    [currentPageDaysAggregates.length]
  );

  return (
    <LoadedDailyChartsContainer>
      <DailyChartPaginationContainer>
        <Pagination
          totalRowsCount={daysAggregates.length}
          tableRowsPerPage={tableRowsPerPage}
          setRowsPerPage={setTableRowsPerPage}
          navigateBasePath={"/daily-fixed-summary"}
          jsxComponent={<DateRange showResetDatesButton />}
        />
      </DailyChartPaginationContainer>
      {currentPageDaysAggregates.map((dayAggregate, index) => (
        <DailyFixedSummaryChartContainer
          key={dayAggregate.date.toString()}
          dayAggregate={dayAggregate}
          zIndex={calculateDailyChartZIndex(index)}
        />
      ))}
      <DailyChartPaginationContainer>
        <Pagination
          totalRowsCount={daysAggregates.length}
          tableRowsPerPage={tableRowsPerPage}
          setRowsPerPage={setTableRowsPerPage}
          navigateBasePath={"/daily-fixed-summary"}
        />
      </DailyChartPaginationContainer>
    </LoadedDailyChartsContainer>
  );
};
