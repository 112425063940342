import { PatientCGMEntryDTO } from "../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientDTO } from "../../../../../../../models/patient-dtos/patient-dto";
import { convertCGMEntryToReading } from "../../../../../../../utils/glucose-point-utils/convert-cgm-entry-to-reading";
import { isGlucosePointInRange } from "../../../../../../../utils/glucose-point-utils/glucose-point-range-utils/is-glucose-point-in-range";

export function buildTargetReadings(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientDTO: PatientDTO
) {
  const targetReadings: PatientCGMEntryDTO[] = [];

  for (const entry of patientCGMEntryDTOs) {
    const reading = convertCGMEntryToReading(entry);

    if (isGlucosePointInRange(patientDTO.type, reading)) {
      targetReadings.push(entry);
    }
  }

  return { targetReadings };
}
