import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import { useBGMResponse } from "../../../context/bgm-response-context";
import { BGMCard, BGMCardHeader } from "../styles";
import { BGMGlucoseBGChart } from "./bgm-glucose-bg-chart/bgm-glucose-bg-chart";
import { GlucoseBGDetails } from "./bgm-glucose-bg-details/bgm-glucose-bg-details";
import {
  BGMGlucoseBGBody,
  BgmGlucoseBGBodyChartAndDetailsContainer,
} from "./styled-bgm-glucose-bg";

export const BGMGlucoseBG = () => {
  const { patientCGMEntryDTOs } = useBGMResponse();

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <BGMCard>
        <BGMCardHeader>
          <p>Glucose (BG)</p>
        </BGMCardHeader>
        <BGMGlucoseBGBody>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </BGMGlucoseBGBody>
      </BGMCard>
    );
  }

  return (
    <BGMCard>
      <BGMCardHeader>
        <p>Glucose (BG)</p>
      </BGMCardHeader>
      <BGMGlucoseBGBody>
        <BgmGlucoseBGBodyChartAndDetailsContainer>
          <div className="arrow arrow-top" />
          <BGMGlucoseBGChart />
          <GlucoseBGDetails />
          <div className="arrow arrow-bottom" />
        </BgmGlucoseBGBodyChartAndDetailsContainer>
      </BGMGlucoseBGBody>
    </BGMCard>
  );
};
