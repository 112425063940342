import { useMemo } from "react";
import { getInRangeNumbers } from "../../../../../../../utils/patient-type-utils";
import { useLoadedCompareLeft } from "../../context/loaded-compare-left-context";
import { calculateTimeInRangesPercentageValues } from "../../../../agp-report/agp-report-response/agp-report-response-cards/utils/time-in-ranges-percentage-utils";
import {
  CompareTimeRangesDetailsContainer,
  CompareTimeRangesDetailsItem,
} from "../../../common-styles/styled-compare-time-in-ranges/styled-compare-time-in-ranges-details";

export const CompareLeftTimeRangesDetails = () => {
  const { patientDTO, groupCgmEntriesByDate } = useLoadedCompareLeft();

  const inRangeNumber = getInRangeNumbers(patientDTO.type);
  const maxRange = inRangeNumber[1];
  const minRange = inRangeNumber[0];

  const {
    veryHighPercentage,
    highPercentage,
    lowPercentage,
    targetPercentage,
    veryLowPercentage,
  } = useMemo(
    () =>
      calculateTimeInRangesPercentageValues(
        groupCgmEntriesByDate,
        minRange,
        maxRange,
        patientDTO
      ),
    [groupCgmEntriesByDate, minRange, maxRange, patientDTO]
  );

  return (
    <CompareTimeRangesDetailsContainer>
      <CompareTimeRangesDetailsItem $height={`${veryHighPercentage}%`}>
        <p>
          Very High <span>{`>`}250 mg/dl</span>
        </p>
        <strong>{veryHighPercentage}%</strong>
      </CompareTimeRangesDetailsItem>
      <CompareTimeRangesDetailsItem $height={`${highPercentage}%`}>
        <p>
          High <span>181 - 250 mg/dl</span>
        </p>
        <strong>{highPercentage}%</strong>
      </CompareTimeRangesDetailsItem>
      <CompareTimeRangesDetailsItem $height={`${targetPercentage}%`}>
        <p>
          Target <span>70 - 180 mg/dl</span>
        </p>
        <strong>{targetPercentage}%</strong>
      </CompareTimeRangesDetailsItem>
      <CompareTimeRangesDetailsItem $height={`${lowPercentage}%`}>
        <p>
          Low <span>54 - 69 mg/dl</span>
        </p>
        <strong>{lowPercentage}%</strong>
      </CompareTimeRangesDetailsItem>
      <CompareTimeRangesDetailsItem $height={`${veryLowPercentage}%`}>
        <p>
          Very Low <span>{`<`} 54</span>
        </p>
        <strong>{veryLowPercentage}%</strong>
      </CompareTimeRangesDetailsItem>
    </CompareTimeRangesDetailsContainer>
  );
};
