import { PatientCGMEntryDTOWithBgmTags } from "../../../../utils/types";

type AfterBreakfastSeries = {
  x: Date;
  y: number;
  tooltip: PatientCGMEntryDTOWithBgmTags;
};

export function buildAfterBreakfastSeries(
  afterBreakfastReadings: PatientCGMEntryDTOWithBgmTags[]
) {
  const afterBreakfastSeries: AfterBreakfastSeries[] = [];

  for (const afterBreakfastReading of afterBreakfastReadings) {
    const dateString = `${afterBreakfastReading.date}T00:00:00`;

    afterBreakfastSeries.push({
      x: new Date(dateString),
      y: afterBreakfastReading.glucoseMGPerDL,
      tooltip: afterBreakfastReading,
    });
  }

  return afterBreakfastSeries;
}
