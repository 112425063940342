import { PatientCGMEntryDTOWithBgmTags } from "../../../../utils/types";

type BeforeDinnerSeries = {
  x: Date;
  bgmTag: string;
  y: number;
  tooltip: PatientCGMEntryDTOWithBgmTags;
};

export function buildBeforeDinnerSeries(
  beforeDinnerReadings: PatientCGMEntryDTOWithBgmTags[]
) {
  const beforeDinnerSeries: BeforeDinnerSeries[] = [];

  for (const beforeDinnerReading of beforeDinnerReadings) {
    const dateString = `${beforeDinnerReading.date}T00:00:00`;

    beforeDinnerSeries.push({
      x: new Date(dateString),
      bgmTag: beforeDinnerReading.bgmTag,
      y: beforeDinnerReading.glucoseMGPerDL,
      tooltip: beforeDinnerReading,
    });
  }

  return beforeDinnerSeries;
}
