import { PatientCGMEntryDTOWithBgmTags } from "../../../../utils/types";

type FastingSeries = {
  x: Date;
  y: number;
  tooltip: PatientCGMEntryDTOWithBgmTags;
};

export function buildFastingSeries(
  fastingReadings: PatientCGMEntryDTOWithBgmTags[]
) {
  const fastingSeries: FastingSeries[] = [];

  for (const fastingReading of fastingReadings) {
    const dateString = `${fastingReading.date}T00:00:00`;

    fastingSeries.push({
      x: new Date(dateString),
      y: fastingReading.glucoseMGPerDL,
      tooltip: fastingReading,
    });
  }

  return fastingSeries;
}
